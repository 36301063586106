import * as actionTypes from "../constants/siteConstants";

export const getSitesReducer = (state = { sites: [] }, action) => {
    switch (action.type) {
        case actionTypes.GET_SITES_REQUEST:
           return {
               ...state,
               sites: action.payload,
           }
        case actionTypes.SAVE_ATTR:
            return {
                ...state,
                sites: action.payload,
            }
        case actionTypes.INSERT_SITE:
            return {
                ...state,
                sites: action.payload,
            }
        case actionTypes.DELETE_SITE:
            return {
               ...state,
               sites: action.payload,
            }
        default:
            return state;
    }
}