import { Row, Col, Container, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { InputGroup } from "react-bootstrap";

const EditSitePageComponent = ({ updateSiteApiRequest, fetchSite }) => {
  const [validated, setValidated] = useState(false);
    const [site, setSite] = useState([]);
    const [updateSiteResponseState, setUpdateSiteResponseState] = useState({ message: "", error: "" });

    const { id } = useParams();
    const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    
    const form = event.currentTarget.elements;
    const name = form.name.value;
    const description = form.description.value;
    const code = form.code.value;
    const address = form.address.value;
    const image = form.image.value;
    
    updateSiteApiRequest(id, name, description, code, address, image)
    .then(data => {
        if (data.success === "site updated") {
            navigate("/admin/sites");
        }
      })
    .catch(er => {
        setUpdateSiteResponseState({ error: er.response.data.message ? er.response.data.message : er.response.data });
    })

    setValidated(true);
  };

    useEffect(() => {
        fetchSite(id)
        .then(data => {
            setSite(data);
        })
        .catch((er) => console.log(er.response.data.message ? er.response.data.message : er.response.data));
    }, [id])

  return (
    <Container>

      <Row className="justify-content-md-center mt-5">
        <Col md={1}>
          <Link to="/admin/sites" className="btn btn-info my-3">
            Indietro
          </Link>
        </Col>
        <Col md={6}>
          <h1>Modifica sito</h1>

          <Row className="mt-5 justify-content-md-center"></Row>

          <Form noValidate validated={validated} onSubmit={handleSubmit}>

            <InputGroup className="mb-3">
                <InputGroup.Text id="inputGroup-sizing-default"
                  style={{
                    width: "100px",
                  }}>
                  Nome
                </InputGroup.Text>
                <Form.Control name="title" defaultValue={site.name} required type="text" />
            </InputGroup>

            <InputGroup className="mb-3">
                <InputGroup.Text id="inputGroup-sizing-default"
                  style={{
                    width: "100px",
                  }}>
                  Descrizione
                </InputGroup.Text>
                <Form.Control name="name" defaultValue={site.description} required type="text" />
            </InputGroup>

            <InputGroup className="mb-3">
                <InputGroup.Text id="inputGroup-sizing-default"
                  style={{
                    width: "100px",
                  }}>
                  Codice
                </InputGroup.Text>
                <Form.Control name="surname" defaultValue={site.code} required type="text" />
            </InputGroup>

            <InputGroup className="mb-3">
                <InputGroup.Text id="inputGroup-sizing-default"
                  style={{
                    width: "100px",
                  }}>
                  Indirizzo
                </InputGroup.Text>
                <Form.Control name="telephone" defaultValue={site.address} required type="text" />
            </InputGroup>

            <InputGroup className="mb-3">
                <InputGroup.Text id="inputGroup-sizing-default"
                  style={{
                    width: "100px",
                  }}>
                  Image
                </InputGroup.Text>
                <Form.Control name="email" defaultValue={site.image} required type="text" />
            </InputGroup>

            <Button variant="primary" type="submit">
              MODIFICA
            </Button>
            {updateSiteResponseState.error}

          </Form>
        </Col>
      </Row>

      <Row className="mt-5 justify-content-md-center"></Row>

    </Container>
  );
};

export default EditSitePageComponent;
