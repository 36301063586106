import * as actionTypes from "../constants/customerConstants";

export const getCustomersReducer = (state = { customers: [] }, action) => {
    switch (action.type) {
        case actionTypes.GET_CUSTOMERS_REQUEST:
           return {
               ...state,
               customers: action.payload,
           }
        case actionTypes.SAVE_ATTR:
            return {
                ...state,
                customers: action.payload,
            }
        case actionTypes.INSERT_CUSTOMER:
            return {
                ...state,
                customers: action.payload,
            }
        case actionTypes.DELETE_CUSTOMER:
            return {
               ...state,
               customers: action.payload,
            }
        default:
            return state;
    }
}