//import { LOGIN_USER, LOGOUT_USER } from '../constants/userConstants'
import * as actionTypes from "../constants/userConstants";
import axios from 'axios'


export const setReduxUserState = (userCreated) => (dispatch) => {
    dispatch({
        type: actionTypes.LOGIN_USER,
        payload: userCreated
    })
}

export const logout = () => (dispatch) => {
    document.location.href = "/login";
    axios.get('/api/logout')
    localStorage.removeItem("userInfo");
    sessionStorage.removeItem("userInfo");
    localStorage.removeItem("cart");
    dispatch({ type: actionTypes.LOGOUT_USER })
}


export const getUsers = () => async (dispatch) => {
    const { data } = await axios.get("/api/users");
    dispatch({
        type: actionTypes.GET_USERS_REQUEST,
        payload: data,
    })
}

export const saveAttributeToCatDoc = (key, val, userChoosen) => async (dispatch, getState) => {
   const { data } = await axios.post("/api/users/attr", { key, val, userChoosen }); 
   if (data.userUpdated) {
       dispatch({
           type: actionTypes.SAVE_ATTR,
           payload: [...data.userUpdated],
       })
   }
}

export const newUser = (user) => async (dispatch, getState) => {
    const res = getState().getUsers.users;
    const { data } = await axios.post("/api/users", { user });
    if (data.userCreated) {
        dispatch({
            type: actionTypes.INSERT_USER,
            payload: [...res, data.userCreated],
        })
    }
}

export const deleteUser = (user) => async (dispatch, getState) => {
    const res = getState().getUsers.users;
    const users = res.filter((item) => item.name !== user);
    const { data } = await axios.delete("/api/users/" + encodeURIComponent(user));
    if (data.userDeleted) {
        dispatch({
           type: actionTypes.DELETE_USER, 
           payload: [...users],
        })
    }
}