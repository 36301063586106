import CreateSitePageComponent from "./components/CreateSitePageComponent";
import axios from "axios";
import { useSelector } from "react-redux";
import { newSite,deleteSite, saveAttributeToCatDoc } from "../../redux/actions/siteActions";
import { useDispatch } from "react-redux";

const createSiteApiRequest = async (formInputs) => {
  const { data } = await axios.post(`/api/sites`, { ...formInputs });
  return data;
};

const AdminCreateSitePage = () => {
  const { sites } = useSelector((state) => state.getCategories);
  const dispatch = useDispatch();

  return (
    <CreateSitePageComponent
      createSiteApiRequest={createSiteApiRequest}
      sites={sites}
      reduxDispatch={dispatch}
      newSite={newSite}
      deleteSite={deleteSite}
      saveAttributeToCatDoc={saveAttributeToCatDoc}
    />
  );
};

export default AdminCreateSitePage;
