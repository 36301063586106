import * as actionTypes from "../constants/siteConstants";

import axios from "axios";

export const getSites = () => async (dispatch) => {
    const { data } = await axios.get("/api/sites");
    dispatch({
        type: actionTypes.GET_SITES_REQUEST,
        payload: data,
    })
}

export const saveAttributeToCatDoc = (key, val, siteChoosen) => async (dispatch, getState) => {
   const { data } = await axios.post("/api/sites/attr", { key, val, siteChoosen }); 
   if (data.siteUpdated) {
       dispatch({
           type: actionTypes.SAVE_ATTR,
           payload: [...data.siteUpdated],
       })
   }
}

export const newSite = (site) => async (dispatch, getState) => {
    const res = getState().getSites.sites;
    const { data } = await axios.post("/api/sites", { site });
    if (data.siteCreated) {
        dispatch({
            type: actionTypes.INSERT_SITE,
            payload: [...res, data.siteCreated],
        })
    }
}

export const deleteSite = (site) => async (dispatch, getState) => {
    const res = getState().getSites.sites;
    const sites = res.filter((item) => item.name !== site);
    const { data } = await axios.delete("/api/sites/" + encodeURIComponent(site));
    if (data.siteDeleted) {
        dispatch({
           type: actionTypes.DELETE_SITE, 
           payload: [...sites],
        })
    }
}