import {
  Row,
  Col,
  Container,
  Form,
  Button,
  CloseButton,
  Table,
  Alert,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { changeUser, setValuesForAttrFromDbSelectForm, setAttributesTableWrapper } from "./utils/utils";
import InputGroup from 'react-bootstrap/InputGroup';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { red } from "@mui/material/colors";
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { pink } from '@mui/material/colors';

const CreateUserPageComponent = ({
  createUserApiRequest,
  registerUserApiRequest,
  reduxDispatch,
  newUser,
  deleteUser,
  saveAttributeToCatDoc
}) => {

  const [validated, setValidated] = useState(false);
  const [attributesTable, setAttributesTable] = useState([]);
  const [attributesFromDb, setAttributesFromDb] = useState([]);
  const [images, setImages] = useState(false);
  const [isCreating, setIsCreating] = useState("");
  const [createProductResponseState, setCreateUserResponseState] = useState({
    message: "",
    error: "",
  });
  const [userChoosen, setUserChoosen] = useState("Choose user");

  const [newAttrKey, setNewAttrKey] = useState(false);
  const [newAttrValue, setNewAttrValue] = useState(false);

  const attrVal = useRef(null);
  const attrKey = useRef(null);
  const createNewAttrKey = useRef(null);
  const createNewAttrVal = useRef(null);

  const [name, setName] = useState("");                      
  const [lastName, setLastName] = useState("");                      
  const [email, setEmail] = useState("");                      
  const [phoneNumber, setPhoneNumber] = useState("");                      
  const [address, setAddress] = useState("");                      
  const [country, setCountry] = useState("");                      
  const [zipCode, setZipCode] = useState("");                      
  const [city, setCity] = useState("");                      
  const [state, setState] = useState("");                      
  const [password, setPassword] = useState("");                      
  const [isadmin, setIsAdmin] = useState(false);                      

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget.elements;
    const formInputs = {
      name: form.name.value,
      lastName: form.lastName.value,
      email: form.email.value,
      isAdmin: form.isAdmin.value,
    };

    createUserApiRequest(formInputs);

    setValidated(true);

    window.location.assign('/admin/users')
  };

  const uploadHandler = (images) => {
    setImages(images);
  };

  const newUserHandler = (e) => {
    if (e.keyCode && e.keyCode === 13 && e.target.value) {
      reduxDispatch(newUser(e.target.value));
      setTimeout(() => {
        let element = document.getElementById("cats");
        setUserChoosen(e.target.value);
        element.value = e.target.value;
        e.target.value = "";
      }, 200);
    }
  };

  const deleteUserHandler = () => {
    let element = document.getElementById("cats");
    reduxDispatch(deleteUser(element.value));
    setUserChoosen("Choose user");
  };

  const attributeValueSelected = (e) => {
      if (e.target.value !== "Choose attribute value") {
          setAttributesTableWrapper(attrKey.current.value, e.target.value, setAttributesTable);
      }
  }

  const deleteAttribute = (key) => {
      setAttributesTable((table) => table.filter((item) => item.key !== key));
  }

  const newAttrKeyHandler = (e) => {
      e.preventDefault();
      setNewAttrKey(e.target.value);
      addNewAttributeManually(e);
  }

  const newAttrValueHandler = (e) => {
      e.preventDefault();
      setNewAttrValue(e.target.value);
      addNewAttributeManually(e);

  }

  const addNewAttributeManually = (e) => {
      if (e.keyCode && e.keyCode === 13) {
           if (newAttrKey && newAttrValue) {
              reduxDispatch(saveAttributeToCatDoc(newAttrKey, newAttrValue, userChoosen));
               setAttributesTableWrapper(newAttrKey, newAttrValue, setAttributesTable);
               e.target.value = "";
               createNewAttrKey.current.value = "";
               createNewAttrVal.current.value = "";
               setNewAttrKey(false);
               setNewAttrValue(false);
           }

      }
  }

  const checkKeyDown = (e) => {
      if (e.code === "Enter") e.preventDefault();
  }

  function registerNewUser (eventKey) {
    
    eventKey.preventDefault();
    eventKey.stopPropagation();

    setState('IT');

    console.log(`name dig =`, name);  
    console.log(`lastName dig =`, lastName);  
    console.log(`email dig =`, email);  
    console.log(`phoneNumber dig =`, phoneNumber);  
    console.log(`address dig =`, address);  
    console.log(`country dig =`, country);  
    console.log(`zipCode dig =`, zipCode);  
    console.log(`city dig =`, city);  
    console.log(`state dig =`, state);  
    console.log(`password dig =`, password);  
    console.log(`isadmin dig =`, isadmin);  

    const formInputs = {
      name: name,
      lastName: lastName,
      email: email,
      phoneNumber: phoneNumber,
      address: address,
      country: country,
      zipCode: zipCode,
      city: city,
      state: state,
      password: password,
      isadmin: isadmin,
    };

    createUserApiRequest(formInputs);
    window.location.assign('/admin/users')
  }

  function gotoIndietro (eventKey) {
    eventKey.preventDefault();
    eventKey.stopPropagation();

    window.location.assign('/admin/users')
  }


  return (

    <Container fullWidth>
      
      <Row className="justify-content-center mt-5">

        <Col md={11}>
          <h1>&nbsp;Crea nuovo utente</h1>

          <Form noValidate validated={validated} onSubmit={handleSubmit} onKeyDown={(e) => checkKeyDown(e)} >

            <Box
              component="form"
              sx={{ '& > :not(style)': { m: 1, width: '25ch' } }}
              noValidate
              autoComplete="off"
            >

              <TextField id="id-name" label="Nome" variant="outlined" defaultValue={name}
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setName(event.target.value);
                }}
              />

              <TextField id="id-lastName" label="Cognome" variant="outlined" defaultValue={lastName} 
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setLastName(event.target.value);
                }}
              />

              <TextField id="id-email" label="Email" variant="outlined" defaultValue={email} 
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
              />

              <TextField id="id-phoneNumber" label="Telefono" variant="outlined" defaultValue={phoneNumber} 
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setPhoneNumber(event.target.value);
                }}
              />

              <TextField id="id-address" label="Indirizzo" variant="outlined" defaultValue={address} 
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setAddress(event.target.value);
                }}
              />
              <TextField id="id-city" label="Città" variant="outlined" defaultValue={city} 
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setCity(event.target.value);
                }}
              />
              <TextField id="id-country" label="Provincia" variant="outlined" defaultValue={country} 
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setCountry(event.target.value);
                }}
              />
              <TextField id="id-zipCode" label="Cap" variant="outlined" defaultValue={zipCode} 
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setZipCode(event.target.value);
                }}
              />

              <TextField id="id-lastName" label="Password" variant="outlined" defaultValue={password} 
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setPassword(event.target.value);
                }}
              />

              <FormControlLabel control={
                <Checkbox id="id-isadmin" label="Admin" variant="outlined" defaultValue={isadmin} 
                  style={{
                    width: "30px",
                  }}
                  onChange={(event) => {
                    setIsAdmin(false);
                  }}
                  value='Required'
                  sx={{
                    color: pink[800],
                    '&.Mui-checked': {
                      color: pink[600],
                    },
                  }}
                />
              } label="Admin" />
           
          </Box>

          </Form>

        </Col>

      </Row>

      <Row className="justify-content-center mt-5">
        <Col md={1}>
  
        <Button 
              type="button"
              className="btn btn-warning"
              onClick={(e) => {gotoIndietro(e); }}
              style={{
                width: "80px",
              }}
            >
              Indietro
            </Button>             

         </Col>

          <Col md={1}>
            <Button 
              type="button"
              className="btn btn-primary"
              onClick={(e) => {registerNewUser(e); }}
              style={{
                width: "80px",
              }}
            >
              Crea
            </Button>             

            {createProductResponseState.error ?? ""}
          </Col>
      </Row>

      <Row className="mt-5 justify-content-md-center"></Row>

    </Container>
  );
};

export default CreateUserPageComponent;
