import React, { useEffect, useState, useRef } from "react";
import ReactDom from "react-dom";
import { Row, Col, Container, Form, Button } from "react-bootstrap";
import { InputGroup } from "react-bootstrap";
import { useSelector } from "react-redux";


import "./../assets/custom-modal.css";

import axios from "axios";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import { useMediaQuery } from 'react-responsive';
import MediaQuery from 'react-responsive'

import Modal from 'react-bootstrap/Modal';
import { Card } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";

import { Rating } from "react-simple-star-rating";

import dayjs from 'dayjs'
import 'dayjs/locale/it.js';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import { DemoContainer, DemoItem } from '@mui/x-date-pickers/internals/demo';
import { itIT } from "@mui/x-date-pickers/locales";
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateCalendar } from '@mui/x-date-pickers/DateCalendar';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';


// costanti generali
const altezza_cella_appuntamento_px=60;    // height:altezza_cella_appuntamento_px => height:90  
const altezza_cella_appuntamento1_px=60;  // height:altezza_cella_appuntamento1_px => height:100  
const colorBackground_cella_senzaAppuntamento='#F0F0F0';

const modalAppuntamento = document.getElementById("modal-appuntamento");

const ModalAppuntamento = props => {

const refModal = useRef();
const [modalOpacity, setModalOpacity] = useState(0);
const transitionTime = 300;
const styles = { transition: `all linear ${transitionTime}ms` };

const durationsMins = [
    {
      "message": '15 minuti',
      "valueMins": 15
    },
    {
      "message": '30 minuti',
      "valueMins": 30
    },
    {
      "message": '45 minuti',
      "valueMins": 45
    },
    {
      "message": '1 ora',
      "valueMins": 60
    },
    {
      "message": '1 ora 15 minuti',
      "valueMins": 75
    },
    {
      "message": '1 ora 30 minuti',
      "valueMins": 90
    },
    {
      "message": '1 ora 55 minuti',
      "valueMins": 105
    },
    {
      "message": '2 ore',
      "valueMins": 120
    },
    {
      "message": '2 ore 15 minuti',
      "valueMins": 135
    },
    {
      "message": '2 ore 30 minuti',
      "valueMins": 150
    },
    {
      "message": '2 ore 45 minuti',
      "valueMins": 165
    },
    {
      "message": '3 ore',
      "valueMins": 180
    },
    {
      "message": '3 ore 15 minuti',
      "valueMins": 195
    },
    {
      "message": '3 ore 30 minuti',
      "valueMins": 210
    },
    {
      "message": '3 ore 45 minuti',
      "valueMins": 225
    },
    {
      "message": '4 ore',
      "valueMins": 240
    }
  ];

  const [mainCategories, setMainCategories] = useState([]);

  const [validated, setValidated] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();

  const closeModalAppuntamento = () => {
    setModalOpacity(0);
    setTimeout(() => props.closeModal(), transitionTime);
  };

  const { pageNumParam } = useParams() || 1;
  const { searchQuery } = useParams() || "";
  const [sortOption, setSortOption] = useState("");
  const [paginationLinksNumber, setPaginationLinksNumber] = useState(null);
  const [pageNum, setPageNum] = useState(null);
  const [filters, setFilters] = useState({}); // collect all filters
  const [products, setProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(false);
  const [attrsFilter, setAttrsFilter] = useState([]); // collect category attributes from db and show on the webpage
  const [attrsFromFilter, setAttrsFromFilter] = useState([]); // collect user filters for category attributes
  
  const [filtersUrl, setFiltersUrl] = useState([]);
  const [proceedFilters, setProceedFilters] = useState([]);

  const [rating, setRating] = useState(null);

  const {categories} = useSelector((state) => state.getCategories);

  //  const {resources} = useSelector((state) => state.getResources);
  //  const {sites} = useSelector((state) => state.getSites);
  
  const [resources, setResources] = useState([]);
  const [sites, setSites] = useState([]);

  const [siteChoosen, setSiteChoosen] = useState("Choose site");
  
  const [product, setProduct] = useState({});

  const isMobile = useMediaQuery({ query: '(min-width: 576px)' });
  const isTablet = useMediaQuery({ query: '(min-width: 768px)' });
  const isPC = useMediaQuery({ query: '(min-width: 992px)' });
  
  const [activeTab, setActiveTab] = useState('appuntamento');
  
  // true/false se valorizzati
  const [isCategoriaScelta, setIsCategoriaScelta] = useState(false);  // è un boleano true/false
  const [isProdottoScelto, setIsProdottoScelto] = useState(false);    // è un boleano true/false
  const [isRisorsaScelta, setIsRisorsaScelta] = useState(false);      // è un boleano true/false
  const [isSitoScelto, setIsSitoScelto] = useState(false);            // è un boleano true/false
  
  // value in variazione nel Modal
  const [oraEditata, setOraEditata] = useState("");                            // è la ora cliccata nelTab

  const [oraScelta, setOraScelta] = useState("");                                 // è la ora cliccata nelTab
  const [clienteDigitato, setClienteDigitato] = useState("");                     // è la ora cliccata nelTab
  const [categoriaScelta, setCategoriaScelta] = useState("scegli la categoria");  // è la categoria cliccata nelTab
  const [prodottoScelto, setProdottoScelto] = useState("scegli il servizio");     // è il servizio/prodotto cliccata nelTab
  const [risorsaScelta, setRisorsaScelta] = useState("schegli la risorsa");       // è la ora risorsa cliccata nelTab
  const [sitoScelto, setSitoScelto] = useState("schegli il sito");                // è la il sito cliccato nelTab
  const [durataScelta, setDurataScelta] = useState("schegli la durata");          // è la la durata cliccata nelTab
  const [ownerScelto, setOwnerScelto] = useState("0000");                         // è l'owner dell'inizio ora appuntamento
  const [coloreScelto1, setColoreScelto1] = useState("scegli il colore");           // è il colore della categoria cliccata nelTab
  
  const [siteSelected, setSiteSelected] = useState();          // è il site cliccato nelTabServizio
  
  const getProducts = async (categoryName , pageNumParam = null, searchQuery = "", filters = {}, sortOption = "") => {
    console.log('categoryName=',categoryName)  
    console.log('categoriaScelta=',categoriaScelta)  
    const search = searchQuery ? `search/${searchQuery}/` : "";
    const category = categoryName ? `category/${categoryName}/` : "";
    const url = `/api/products/category/${categoryName}`;
    const { data } = await axios.get(url);
    return data
  }

  const getResource = async () => {
    const url = `/api/resources`;
    const { data } = await axios.get(url);
    return data
  }

  const getSites = async () => {
    const url = `/api/sites`;
    const { data } = await axios.get(url);
    return data
  }

  const createAgendaNewAppointmentApiRequest = async (parametri) => {
    const { data } = await axios.post(`/api/agendas`, { ...parametri });
    return data;
  };

  const updateAgendaNewAppointmentApiRequest = async (parametri) => {
    const { data } = await axios.put(`/api/agendas/id/`+parametri?._id, { ...parametri });
    return data;
  };
 

  function prenotazione (eventKey,datiGiornoAgenda) {
    eventKey.preventDefault();
    eventKey.stopPropagation();

    console.log(`[prenotazione] riga  =`, props.riga);  
    console.log(`[prenotazione] colonna  =`, props.colonna);  

    //console.log(`prenotazione`);  
    //console.log(`servizio=`,  props.giornoAgenda.appointments0700[1].resourceTitle);  
    //console.log(`categoria org =`, datiGiornoAgenda?.appointments0700[rigaAppointment].customer);  

    console.log(`[prenotazione] day.customer =`, datiGiornoAgenda.resource0001appointments[0].appointment.customer);  

    console.log(`[prenotazione] id =`, datiGiornoAgenda._id);  
    console.log(`[prenotazione] day =`, datiGiornoAgenda.day);  

    console.log(`[prenotazione] categoriaScelta =`, categoriaScelta);  
    console.log(`[prenotazione] colore =`, props.colore);  
    console.log(`[prenotazione] clienteDigitato =`, clienteDigitato);  
    console.log(`[prenotazione] durataScelta =`, durataScelta);  
    console.log(`[prenotazione] prodottoScelto =`, prodottoScelto);  
    console.log(`[prenotazione] risorsa =`, datiGiornoAgenda.columnResource[props.colonna].resourceTitle);  
    console.log(`[prenotazione] sitoScelto =`, sitoScelto);  
    console.log(`[prenotazione] ownerScelto =`,ownerScelto);  

    // --- trovo il colore
    // clickCellaAppuntamento] categoria = Taglio uomo
    console.log(`[prenotazione] categories =`,categories);  

    let coloreDaCategoria= colorBackground_cella_senzaAppuntamento;
    categories.forEach((values, keys) => {
      if (values.name===categoriaScelta)
      {
        console.log('trovato colore =',values.color);
        coloreDaCategoria=values.color;
      }  
      console.log(values, keys);
    });    
     
    console.log(`[prenotazione] coloreDaCategoria =`,coloreDaCategoria);  
    
    // ciclo durata (per ciascun 15minuti)
    
    console.log(`[prenotazione] ownerScelto =`,ownerScelto);  

    let rigaOrarioDaAggiornare=props.riga;
    let rigaOrarioDaAggiornareDurataScelta=durataScelta;
    let rigaOrarioDaAggiornareOwner=ownerScelto;

    let step=0;
    do {
      console.log(`[prenotazione] aggiorno DB con step = `, step);       
      console.log(`[prenotazione] aggiorno DB rigaOrarioDaAggiornareDurataScelta = `, rigaOrarioDaAggiornareDurataScelta);       
      console.log(`[prenotazione] aggiorno DB rigaOrarioDaAggiornareOwner = `, rigaOrarioDaAggiornareOwner);       

      // -------------------- aggiorno dayAgenda (PUT)
      //
      //      storicizzo le celle con i valori del nuovo appuntamento

      // colonna = risorsa => decide che array  
      if (props.colonna===0) {
        datiGiornoAgenda.resource0001appointments[rigaOrarioDaAggiornare].appointment.category=categoriaScelta;
        datiGiornoAgenda.resource0001appointments[rigaOrarioDaAggiornare].appointment.color=coloreDaCategoria;
        datiGiornoAgenda.resource0001appointments[rigaOrarioDaAggiornare].appointment.customer=clienteDigitato; 
        datiGiornoAgenda.resource0001appointments[rigaOrarioDaAggiornare].appointment.durationMins=rigaOrarioDaAggiornareDurataScelta; 
        datiGiornoAgenda.resource0001appointments[rigaOrarioDaAggiornare].appointment.product=prodottoScelto;
        datiGiornoAgenda.resource0001appointments[rigaOrarioDaAggiornare].appointment.resourceTitle=datiGiornoAgenda.columnResource[props.colonna].resourceTitle; 
        datiGiornoAgenda.resource0001appointments[rigaOrarioDaAggiornare].appointment.site=sitoScelto; 
        datiGiornoAgenda.resource0001appointments[rigaOrarioDaAggiornare].appointment.owner=rigaOrarioDaAggiornareOwner; 
      }  
      if (props.colonna===1) {
        datiGiornoAgenda.resource0002appointments[rigaOrarioDaAggiornare].appointment.category=categoriaScelta;
        datiGiornoAgenda.resource0002appointments[rigaOrarioDaAggiornare].appointment.color=coloreDaCategoria;
        datiGiornoAgenda.resource0002appointments[rigaOrarioDaAggiornare].appointment.customer=clienteDigitato; 
        datiGiornoAgenda.resource0002appointments[rigaOrarioDaAggiornare].appointment.durationMins=rigaOrarioDaAggiornareDurataScelta; 
        datiGiornoAgenda.resource0002appointments[rigaOrarioDaAggiornare].appointment.product=prodottoScelto;
        datiGiornoAgenda.resource0002appointments[rigaOrarioDaAggiornare].appointment.resourceTitle=datiGiornoAgenda.columnResource[props.colonna].resourceTitle; 
        datiGiornoAgenda.resource0002appointments[rigaOrarioDaAggiornare].appointment.site=sitoScelto; 
        datiGiornoAgenda.resource0002appointments[rigaOrarioDaAggiornare].appointment.owner=rigaOrarioDaAggiornareOwner; 
      }  
      if (props.colonna===2) {
        datiGiornoAgenda.resource0003appointments[rigaOrarioDaAggiornare].appointment.category=categoriaScelta;
        datiGiornoAgenda.resource0003appointments[rigaOrarioDaAggiornare].appointment.color=coloreDaCategoria;
        datiGiornoAgenda.resource0003appointments[rigaOrarioDaAggiornare].appointment.customer=clienteDigitato; 
        datiGiornoAgenda.resource0003appointments[rigaOrarioDaAggiornare].appointment.durationMins=rigaOrarioDaAggiornareDurataScelta; 
        datiGiornoAgenda.resource0003appointments[rigaOrarioDaAggiornare].appointment.product=prodottoScelto;
        datiGiornoAgenda.resource0003appointments[rigaOrarioDaAggiornare].appointment.resourceTitle=datiGiornoAgenda.columnResource[props.colonna].resourceTitle; 
        datiGiornoAgenda.resource0003appointments[rigaOrarioDaAggiornare].appointment.site=sitoScelto; 
        datiGiornoAgenda.resource0003appointments[rigaOrarioDaAggiornare].appointment.owner=rigaOrarioDaAggiornareOwner; 
      }  
      if (props.colonna===3) {
        datiGiornoAgenda.resource0004appointments[rigaOrarioDaAggiornare].appointment.category=categoriaScelta;
        datiGiornoAgenda.resource0004appointments[rigaOrarioDaAggiornare].appointment.color=coloreDaCategoria;
        datiGiornoAgenda.resource0004appointments[rigaOrarioDaAggiornare].appointment.customer=clienteDigitato; 
        datiGiornoAgenda.resource0004appointments[rigaOrarioDaAggiornare].appointment.durationMins=rigaOrarioDaAggiornareDurataScelta; 
        datiGiornoAgenda.resource0004appointments[rigaOrarioDaAggiornare].appointment.product=prodottoScelto;
        datiGiornoAgenda.resource0004appointments[rigaOrarioDaAggiornare].appointment.resourceTitle=datiGiornoAgenda.columnResource[props.colonna].resourceTitle; 
        datiGiornoAgenda.resource0004appointments[rigaOrarioDaAggiornare].appointment.site=sitoScelto; 
        datiGiornoAgenda.resource0004appointments[rigaOrarioDaAggiornare].appointment.owner=rigaOrarioDaAggiornareOwner; 
      }  
      if (props.colonna===4) {
        datiGiornoAgenda.resource0005appointments[rigaOrarioDaAggiornare].appointment.category=categoriaScelta;
        datiGiornoAgenda.resource0005appointments[rigaOrarioDaAggiornare].appointment.color=coloreDaCategoria;
        datiGiornoAgenda.resource0005appointments[rigaOrarioDaAggiornare].appointment.customer=clienteDigitato; 
        datiGiornoAgenda.resource0005appointments[rigaOrarioDaAggiornare].appointment.durationMins=rigaOrarioDaAggiornareDurataScelta; 
        datiGiornoAgenda.resource0005appointments[rigaOrarioDaAggiornare].appointment.product=prodottoScelto;
        datiGiornoAgenda.resource0005appointments[rigaOrarioDaAggiornare].appointment.resourceTitle=datiGiornoAgenda.columnResource[props.colonna].resourceTitle; 
        datiGiornoAgenda.resource0005appointments[rigaOrarioDaAggiornare].appointment.site=sitoScelto; 
        datiGiornoAgenda.resource0005appointments[rigaOrarioDaAggiornare].appointment.owner=rigaOrarioDaAggiornareOwner; 
      }  
      if (props.colonna===5) {
        datiGiornoAgenda.resource0006appointments[rigaOrarioDaAggiornare].appointment.category=categoriaScelta;
        datiGiornoAgenda.resource0006appointments[rigaOrarioDaAggiornare].appointment.color=coloreDaCategoria;
        datiGiornoAgenda.resource0006appointments[rigaOrarioDaAggiornare].appointment.customer=clienteDigitato; 
        datiGiornoAgenda.resource0006appointments[rigaOrarioDaAggiornare].appointment.durationMins=rigaOrarioDaAggiornareDurataScelta; 
        datiGiornoAgenda.resource0006appointments[rigaOrarioDaAggiornare].appointment.product=prodottoScelto;
        datiGiornoAgenda.resource0006appointments[rigaOrarioDaAggiornare].appointment.resourceTitle=datiGiornoAgenda.columnResource[props.colonna].resourceTitle; 
        datiGiornoAgenda.resource0006appointments[rigaOrarioDaAggiornare].appointment.site=sitoScelto; 
        datiGiornoAgenda.resource0006appointments[rigaOrarioDaAggiornare].appointment.owner=rigaOrarioDaAggiornareOwner; 
      }  

      //console.log(`categoria upd =`, datiGiornoAgenda);  
      //updateAgendaNewAppointmentApiRequest(datiGiornoAgenda);

      rigaOrarioDaAggiornare=rigaOrarioDaAggiornare+1;
      rigaOrarioDaAggiornareDurataScelta=0; 
      step=step+15;

    } while (step<durataScelta);

    // -------------------- bonifico dayAgenda (PUT)
    //
    //    azzero le eventuali celle per precedente appuntamento con durata più lunga
    //    se il record ha (.owner = rigaOrarioDaAggiornareOwner)
    //val(props.riga)+step<22

    console.log(`[prenotazione] bonifica  rigaOrarioDaAggiornare prima del ciclo = `, rigaOrarioDaAggiornare);

    step=durataScelta;
    do {
      console.log(`[prenotazione] bonifica DB props.colonna = `, props.colonna);       
      console.log(`[prenotazione] bonifica DB con step = `, step);       

      console.log(`[prenotazione] bonifica DB rigaOrarioDaAggiornareDurataScelta = `, rigaOrarioDaAggiornareDurataScelta);       
      console.log(`[prenotazione] bonifica DB rigaOrarioDaAggiornareOwner = `, rigaOrarioDaAggiornareOwner);       

      console.log(`[prenotazione] bonifica  rigaOrarioDaAggiornare = `, rigaOrarioDaAggiornare);

      // colonna = risorsa => decide che array  
      if (props.colonna===0) 
      {  
        if (datiGiornoAgenda.resource0001appointments[rigaOrarioDaAggiornare].appointment.owner===rigaOrarioDaAggiornareOwner)
        {
          console.log(`[prenotazione] bonifica sulla riga  = `, rigaOrarioDaAggiornare);
          console.log(`[prenotazione] bonifica 1  = `, datiGiornoAgenda.resource0001appointments[rigaOrarioDaAggiornare].appointment.owner);
          console.log(`[prenotazione] bonifica 2  = `, rigaOrarioDaAggiornareOwner);

          datiGiornoAgenda.resource0001appointments[rigaOrarioDaAggiornare].appointment.category="";
          datiGiornoAgenda.resource0001appointments[rigaOrarioDaAggiornare].appointment.color="";
          datiGiornoAgenda.resource0001appointments[rigaOrarioDaAggiornare].appointment.customer=""; 
          datiGiornoAgenda.resource0001appointments[rigaOrarioDaAggiornare].appointment.durationMins=0; 
          datiGiornoAgenda.resource0001appointments[rigaOrarioDaAggiornare].appointment.product="";
          datiGiornoAgenda.resource0001appointments[rigaOrarioDaAggiornare].appointment.resourceTitle=""; 
          datiGiornoAgenda.resource0001appointments[rigaOrarioDaAggiornare].appointment.site=""; 
          datiGiornoAgenda.resource0001appointments[rigaOrarioDaAggiornare].appointment.owner=datiGiornoAgenda.resource0001appointments[rigaOrarioDaAggiornare].hour;    
        }
      }      

      if (props.colonna===1) 
      {  
        if (datiGiornoAgenda.resource0002appointments[rigaOrarioDaAggiornare].appointment.owner===rigaOrarioDaAggiornareOwner)
        {
          console.log(`[prenotazione] bonifica sulla riga  = `, rigaOrarioDaAggiornare);

          datiGiornoAgenda.resource0002appointments[rigaOrarioDaAggiornare].appointment.category="";
          datiGiornoAgenda.resource0002appointments[rigaOrarioDaAggiornare].appointment.color="";
          datiGiornoAgenda.resource0002appointments[rigaOrarioDaAggiornare].appointment.customer=""; 
          datiGiornoAgenda.resource0002appointments[rigaOrarioDaAggiornare].appointment.durationMins=0; 
          datiGiornoAgenda.resource0002appointments[rigaOrarioDaAggiornare].appointment.product="";
          datiGiornoAgenda.resource0002appointments[rigaOrarioDaAggiornare].appointment.resourceTitle=""; 
          datiGiornoAgenda.resource0002appointments[rigaOrarioDaAggiornare].appointment.site=""; 
          datiGiornoAgenda.resource0002appointments[rigaOrarioDaAggiornare].appointment.owner=datiGiornoAgenda.resource0002appointments[rigaOrarioDaAggiornare].hour;    
        }  
      }
       
      if (props.colonna===2) 
      {  
        if (datiGiornoAgenda.resource0003appointments[rigaOrarioDaAggiornare].appointment.owner===rigaOrarioDaAggiornareOwner)
        {
          console.log(`[prenotazione] bonifica sulla riga  = `, rigaOrarioDaAggiornare);

          datiGiornoAgenda.resource0003appointments[rigaOrarioDaAggiornare].appointment.category="";
          datiGiornoAgenda.resource0003appointments[rigaOrarioDaAggiornare].appointment.color="";
          datiGiornoAgenda.resource0003appointments[rigaOrarioDaAggiornare].appointment.customer=""; 
          datiGiornoAgenda.resource0003appointments[rigaOrarioDaAggiornare].appointment.durationMins=0; 
          datiGiornoAgenda.resource0003appointments[rigaOrarioDaAggiornare].appointment.product="";
          datiGiornoAgenda.resource0003appointments[rigaOrarioDaAggiornare].appointment.resourceTitle=""; 
          datiGiornoAgenda.resource0003appointments[rigaOrarioDaAggiornare].appointment.site=""; 
          datiGiornoAgenda.resource0003appointments[rigaOrarioDaAggiornare].appointment.owner=datiGiornoAgenda.resource0003appointments[rigaOrarioDaAggiornare].hour;    
        }
      }

      if (props.colonna===3) 
      {  
        if (datiGiornoAgenda.resource0004appointments[rigaOrarioDaAggiornare].appointment.owner===rigaOrarioDaAggiornareOwner)
        {
          console.log(`[prenotazione] bonifica sulla riga  = `, rigaOrarioDaAggiornare);
          
          datiGiornoAgenda.resource0004appointments[rigaOrarioDaAggiornare].appointment.category="";
          datiGiornoAgenda.resource0004appointments[rigaOrarioDaAggiornare].appointment.color="";
          datiGiornoAgenda.resource0004appointments[rigaOrarioDaAggiornare].appointment.customer=""; 
          datiGiornoAgenda.resource0004appointments[rigaOrarioDaAggiornare].appointment.durationMins=0; 
          datiGiornoAgenda.resource0004appointments[rigaOrarioDaAggiornare].appointment.product="";
          datiGiornoAgenda.resource0004appointments[rigaOrarioDaAggiornare].appointment.resourceTitle=""; 
          datiGiornoAgenda.resource0004appointments[rigaOrarioDaAggiornare].appointment.site=""; 
          datiGiornoAgenda.resource0004appointments[rigaOrarioDaAggiornare].appointment.owner=datiGiornoAgenda.resource0004appointments[rigaOrarioDaAggiornare].hour;    
        }
      }    
       
      if (props.colonna===4) 
      {  
        if (datiGiornoAgenda.resource0005appointments[rigaOrarioDaAggiornare].appointment.owner===rigaOrarioDaAggiornareOwner)
        {
          datiGiornoAgenda.resource0005appointments[rigaOrarioDaAggiornare].appointment.category="";
          datiGiornoAgenda.resource0005appointments[rigaOrarioDaAggiornare].appointment.color="";
          datiGiornoAgenda.resource0005appointments[rigaOrarioDaAggiornare].appointment.customer=""; 
          datiGiornoAgenda.resource0005appointments[rigaOrarioDaAggiornare].appointment.durationMins=0; 
          datiGiornoAgenda.resource0005appointments[rigaOrarioDaAggiornare].appointment.product="";
          datiGiornoAgenda.resource0005appointments[rigaOrarioDaAggiornare].appointment.resourceTitle=""; 
          datiGiornoAgenda.resource0005appointments[rigaOrarioDaAggiornare].appointment.site=""; 
          datiGiornoAgenda.resource0005appointments[rigaOrarioDaAggiornare].appointment.owner=datiGiornoAgenda.resource0005appointments[rigaOrarioDaAggiornare].hour;    
        }  
      }

      if (props.colonna===5) 
      {  
        if (datiGiornoAgenda.resource0006appointments[rigaOrarioDaAggiornare].appointment.owner===rigaOrarioDaAggiornareOwner)
        {
          datiGiornoAgenda.resource0006appointments[rigaOrarioDaAggiornare].appointment.category="";
          datiGiornoAgenda.resource0006appointments[rigaOrarioDaAggiornare].appointment.color="";
          datiGiornoAgenda.resource0006appointments[rigaOrarioDaAggiornare].appointment.customer=""; 
          datiGiornoAgenda.resource0006appointments[rigaOrarioDaAggiornare].appointment.durationMins=0; 
          datiGiornoAgenda.resource0006appointments[rigaOrarioDaAggiornare].appointment.product="";
          datiGiornoAgenda.resource0006appointments[rigaOrarioDaAggiornare].appointment.resourceTitle=""; 
          datiGiornoAgenda.resource0006appointments[rigaOrarioDaAggiornare].appointment.site=""; 
          datiGiornoAgenda.resource0006appointments[rigaOrarioDaAggiornare].appointment.owner=datiGiornoAgenda.resource0006appointments[rigaOrarioDaAggiornare].hour;    
        }  

      } 

      //console.log(`categoria upd =`, datiGiornoAgenda);  

      rigaOrarioDaAggiornare=rigaOrarioDaAggiornare+1;

      step=step+15;
      console.log(`[prenotazione] bonifica raggiunto step = `, step);

    } while (step<240 && rigaOrarioDaAggiornare<60);

    updateAgendaNewAppointmentApiRequest(datiGiornoAgenda);

    // chiudi modale
    setModalOpacity(0);
    setTimeout(() => props.closeModal(), transitionTime);

  };

        
  function popupConfermaCancellazione (eventKey,datiGiornoAgenda) {
    const choice = window.confirm(
      "Confermi la cancellazione dell'appuntamento?"
    );
    if (choice) {
      // cancella appuntamento

      console.log(`[cancella] riga  =`, props.riga);  
      console.log(`[cancella] colonna  =`, props.colonna);  

      //console.log(`servizio=`,  props.giornoAgenda.appointments0700[1].resourceTitle);  
      //console.log(`categoria org =`, datiGiornoAgenda?.appointments0700[rigaAppointment].customer);  

      console.log(`[cancella] day.customer =`, datiGiornoAgenda.resource0001appointments[0].appointment.customer);  

      console.log(`[cancella] id =`, datiGiornoAgenda._id);  
      console.log(`[cancella] day =`, datiGiornoAgenda.day);  

      console.log(`[cancella] categoriaScelta =`, categoriaScelta);  
      console.log(`[cancella] colore =`, props.colore);  
      console.log(`[cancella] clienteDigitato =`, clienteDigitato);  
      console.log(`[cancella] durataScelta =`, durataScelta);  
      console.log(`[cancella] prodottoScelto =`, prodottoScelto);  
      console.log(`[cancella] risorsa =`, datiGiornoAgenda.columnResource[props.colonna].resourceTitle);  
      console.log(`[cancella] sitoScelto =`, sitoScelto);  
      console.log(`[cancella] ownerScelto =`,props.owner);  


    // ciclo durata (per ciascun 15minuti)
    
    let rigaOrarioDaAggiornare=props.riga;
    //let rigaOrarioDaAggiornareDurataScelta=durataScelta;
    let rigaOrarioDaAggiornareOwner=ownerScelto;

    let step=0;
    do {
      console.log(`[cancella] aggiorno DB con step = `, step);       
      console.log(`[cancella] aggiorno DB rigaOrarioDaAggiornare = `, rigaOrarioDaAggiornareOwner);       

      // -------------------- aggiorno dayAgenda (PUT)
      //
      //      storicizzo blank le celle con i valori del vecchio appuntamento

      // colonna = risorsa => decide che array  
      if (props.colonna===0) {
        datiGiornoAgenda.resource0001appointments[rigaOrarioDaAggiornare].appointment.category="";
        datiGiornoAgenda.resource0001appointments[rigaOrarioDaAggiornare].appointment.color="";
        datiGiornoAgenda.resource0001appointments[rigaOrarioDaAggiornare].appointment.customer=""; 
        datiGiornoAgenda.resource0001appointments[rigaOrarioDaAggiornare].appointment.durationMins=0; 
        datiGiornoAgenda.resource0001appointments[rigaOrarioDaAggiornare].appointment.product="";
        datiGiornoAgenda.resource0001appointments[rigaOrarioDaAggiornare].appointment.resourceTitle=""; 
        datiGiornoAgenda.resource0001appointments[rigaOrarioDaAggiornare].appointment.site=""; 
        datiGiornoAgenda.resource0001appointments[rigaOrarioDaAggiornare].appointment.owner=datiGiornoAgenda.resource0001appointments[rigaOrarioDaAggiornare].hour; 
      }  
      if (props.colonna===1) {
        datiGiornoAgenda.resource0002appointments[rigaOrarioDaAggiornare].appointment.category="";
        datiGiornoAgenda.resource0002appointments[rigaOrarioDaAggiornare].appointment.color="";
        datiGiornoAgenda.resource0002appointments[rigaOrarioDaAggiornare].appointment.customer=""; 
        datiGiornoAgenda.resource0002appointments[rigaOrarioDaAggiornare].appointment.durationMins=0; 
        datiGiornoAgenda.resource0002appointments[rigaOrarioDaAggiornare].appointment.product="";
        datiGiornoAgenda.resource0002appointments[rigaOrarioDaAggiornare].appointment.resourceTitle=""; 
        datiGiornoAgenda.resource0002appointments[rigaOrarioDaAggiornare].appointment.site=""; 
        datiGiornoAgenda.resource0002appointments[rigaOrarioDaAggiornare].appointment.owner=datiGiornoAgenda.resource0002appointments[rigaOrarioDaAggiornare].hour; 
      }  
      if (props.colonna===2) {
        datiGiornoAgenda.resource0003appointments[rigaOrarioDaAggiornare].appointment.category="";
        datiGiornoAgenda.resource0003appointments[rigaOrarioDaAggiornare].appointment.color="";
        datiGiornoAgenda.resource0003appointments[rigaOrarioDaAggiornare].appointment.customer=""; 
        datiGiornoAgenda.resource0003appointments[rigaOrarioDaAggiornare].appointment.durationMins=0; 
        datiGiornoAgenda.resource0003appointments[rigaOrarioDaAggiornare].appointment.product="";
        datiGiornoAgenda.resource0003appointments[rigaOrarioDaAggiornare].appointment.resourceTitle=""; 
        datiGiornoAgenda.resource0003appointments[rigaOrarioDaAggiornare].appointment.site=""; 
        datiGiornoAgenda.resource0003appointments[rigaOrarioDaAggiornare].appointment.owner=datiGiornoAgenda.resource0003appointments[rigaOrarioDaAggiornare].hour; 
      }  
      if (props.colonna===3) {
        datiGiornoAgenda.resource0004appointments[rigaOrarioDaAggiornare].appointment.category="";
        datiGiornoAgenda.resource0004appointments[rigaOrarioDaAggiornare].appointment.color="";
        datiGiornoAgenda.resource0004appointments[rigaOrarioDaAggiornare].appointment.customer=""; 
        datiGiornoAgenda.resource0004appointments[rigaOrarioDaAggiornare].appointment.durationMins=0; 
        datiGiornoAgenda.resource0004appointments[rigaOrarioDaAggiornare].appointment.product="";
        datiGiornoAgenda.resource0004appointments[rigaOrarioDaAggiornare].appointment.resourceTitle=""; 
        datiGiornoAgenda.resource0004appointments[rigaOrarioDaAggiornare].appointment.site=""; 
        datiGiornoAgenda.resource0004appointments[rigaOrarioDaAggiornare].appointment.owner=datiGiornoAgenda.resource0004appointments[rigaOrarioDaAggiornare].hour; 
      }  
      if (props.colonna===4) {
        datiGiornoAgenda.resource0005appointments[rigaOrarioDaAggiornare].appointment.category="";
        datiGiornoAgenda.resource0005appointments[rigaOrarioDaAggiornare].appointment.color="";
        datiGiornoAgenda.resource0005appointments[rigaOrarioDaAggiornare].appointment.customer=""; 
        datiGiornoAgenda.resource0005appointments[rigaOrarioDaAggiornare].appointment.durationMins=0; 
        datiGiornoAgenda.resource0005appointments[rigaOrarioDaAggiornare].appointment.product="";
        datiGiornoAgenda.resource0005appointments[rigaOrarioDaAggiornare].appointment.resourceTitle=""; 
        datiGiornoAgenda.resource0005appointments[rigaOrarioDaAggiornare].appointment.site=""; 
        datiGiornoAgenda.resource0005appointments[rigaOrarioDaAggiornare].appointment.owner=datiGiornoAgenda.resource0005appointments[rigaOrarioDaAggiornare].hour; 
      }  
      if (props.colonna===5) {
        datiGiornoAgenda.resource0006appointments[rigaOrarioDaAggiornare].appointment.category="";
        datiGiornoAgenda.resource0006appointments[rigaOrarioDaAggiornare].appointment.color="";
        datiGiornoAgenda.resource0006appointments[rigaOrarioDaAggiornare].appointment.customer=""; 
        datiGiornoAgenda.resource0006appointments[rigaOrarioDaAggiornare].appointment.durationMins=0; 
        datiGiornoAgenda.resource0006appointments[rigaOrarioDaAggiornare].appointment.product="";
        datiGiornoAgenda.resource0006appointments[rigaOrarioDaAggiornare].appointment.resourceTitle=""; 
        datiGiornoAgenda.resource0006appointments[rigaOrarioDaAggiornare].appointment.site=""; 
        datiGiornoAgenda.resource0006appointments[rigaOrarioDaAggiornare].appointment.owner=datiGiornoAgenda.resource0006appointments[rigaOrarioDaAggiornare].hour; 
      }  

      //console.log(`categoria upd =`, datiGiornoAgenda);  
      //updateAgendaNewAppointmentApiRequest(datiGiornoAgenda);

      rigaOrarioDaAggiornare=rigaOrarioDaAggiornare+1;
      //rigaOrarioDaAggiornareDurataScelta=0; 
      step=step+15;

    } while (step<durataScelta);

      //console.log(`categoria upd =`, datiGiornoAgenda.appointments0700[rigaAppointment].customer);  

      updateAgendaNewAppointmentApiRequest(datiGiornoAgenda);

      // chiudi modale
      setModalOpacity(0);
      setTimeout(() => props.closeModal(), transitionTime);
      
      //setCategoriaScelta("");
      //setProdottoScelto("");
      //setSitoScelto("");
    }
  };
      
const createEventsApiRequest = async (eventId, title, name, surname, telephone, email, address) => {
  const { data } = await axios.put(`/api/events/${eventId}`, { title, name, surname, telephone, email, address });
  return data;
}

function handleSelezionaCategoriaInTabCategoria (eventKey,category) {
  eventKey.preventDefault();
  eventKey.stopPropagation();

  console.log(`handleSelezionaCategoriaInTabCategoria`);  
  setIsCategoriaScelta(true);
  setCategoriaScelta(category.name);
  console.log(`categoriaScelta=`,categoriaScelta);  

  // carica i prodotti della categoria

  getProducts(category.name, pageNumParam, searchQuery, filters, sortOption)
  .then((products) => {
    setProducts(products.products);
    setPaginationLinksNumber(products.paginationLinksNumber);
    setPageNum(products.pageNum);
    console.log('products=',products); 
  })
  .catch((er) => {
    console.log(er);
    setError(true);
  });  

  // passa a tabServizio
  setActiveTab(3);
}

function handleSelezionaServizioInTabServizio (eventKey,service) {
  eventKey.preventDefault();
  eventKey.stopPropagation();

  console.log(`handleSelezionaServizioInTabServizio`);  
  setIsProdottoScelto(true);
  setProdottoScelto(service);
  console.log(`prodottoScelto=`,prodottoScelto);  
  // passa a tabServizio

  setActiveTab(1);

}

  useEffect(() => {
    setActiveTab(1);
    setModalOpacity(1);
    const handleClick = e => {
      if (refModal.current.contains(e.target)) {
        // inside click
        return;
      }
      // outside click
      closeModalAppuntamento();
    };
    // add when rendered
    document.addEventListener("mousedown", handleClick);
    // return clean up function to be called when unmounted
    return () => {
      document.removeEventListener("mousedown", handleClick);
    };
  }, []);


  useEffect(() => {
    getResource().then((res) => setResources(res))
    .catch((er) => {
      console.log(er);
    });  
  }, []);
  

  useEffect(() => {
    getSites().then((sit) => setSites(sit))
    .catch((er) => {
      console.log(er);
    });  
  }, []);
  
  useEffect(() => {
    //setCategoriaScelta(props.categoria);
    //setCategoriaScelta(props.categoria);
    //setSitoScelto(props.sito);
    setClienteDigitato(props.cliente);
  }, []);

  useEffect(() => {
    getProducts(props.categoria, pageNumParam, searchQuery, filters, sortOption)
      .then((products) => {
        setProducts(products.products);
        setPaginationLinksNumber(products.paginationLinksNumber);
        setPageNum(products.pageNum);
        console.log('products=',products); 
      })
      .catch((er) => {
        console.log(er);
        setError(true);
      });  
      setProdottoScelto(props.prodotto); 
      setCategoriaScelta(props.categoria); 
      setDurataScelta(props.durata); 
      setSitoScelto(props.sito); 
      setOwnerScelto(props.owner);
  }, []);

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget.elements;
    const formInputs = {
      title: form.title.value,
      name: form.name.value,
      surname: form.surname.value,
      telephone: form.telephone.value,
      email: form.email.value,
      address: form.address.value,
    };
    createEventsApiRequest(formInputs);
    setValidated(true);
    //window.location.assign('/admin/resources')
  };
   
  const handleChangeCategoria = (event) => {
    setCategoriaScelta(event.target.value);
    getProducts(event.target.value, pageNumParam, searchQuery, filters, sortOption)
      .then((products) => {
        setProducts(products.products);
        setPaginationLinksNumber(products.paginationLinksNumber);
        setPageNum(products.pageNum);
        console.log('combo =',event.target.value); 
        console.log('categoriaScelta=',categoriaScelta); 
        console.log('products=',products); 
      })
      .catch((er) => {
        console.log(er);
        setError(true);
      });  
      setProdottoScelto(products[0].name);
  };

  const handleChangeServizio = (event) => {
    setProdottoScelto(event.target.value);
  };

  const handleChangeSito = (event) => {
    setSitoScelto(event.target.value);
  };

  const handleChangeDurata = (event) => {
    setDurataScelta(event.target.value);
    console.log('[handleChangeDurata} event.target.value=',event.target.value); 

  };

  const CardCategorySelection = ({ category, idx }) => { 
    return (
      <Card body outline color="success" className="mx-2 my-2">
        <Card.Img crossOrigin="anonymous" variant="top" src={category.image ?? null} />
        <Card.Body>
          <Card.Title>{category.name}</Card.Title>
          <Card.Text>
            {category.description}
          </Card.Text>
            <Button variant="success" onClick={(e) => {handleSelezionaCategoriaInTabCategoria(e, category); }}>
              Seleziona
            </Button>
        </Card.Body>
      </Card>   
    );
  };

  const CardProductSelection = ({ productId, name, description, price, images, rating, reviewsNumber }) => {
    return (
      <Card style={{ marginTop: "30px", marginBottom: "50px" }}>
        <Row>
          <Col lg={5}>
            <Card.Img
              crossOrigin="anonymous"
              variant="top"
              src={images[0] ? images[0].path : ''}
            />
          </Col>
          <Col lg={7}>
            <Card.Body>
              <Card.Title>{name}</Card.Title>
              <Card.Text>
                {description}
              </Card.Text>
              <Card.Text>
                <Rating readonly size={20} initialValue={rating} /> ({reviewsNumber})
              </Card.Text>
              <Card.Text className="h4">
                €{price}{" "}
                <Button variant="success" onClick={(e) => {handleSelezionaServizioInTabServizio(e, name); }}>
                  Seleziona
                </Button>
              </Card.Text>
            </Card.Body>
          </Col>
        </Row>
      </Card>
    );
  };

  const ViewTabServizioCategoriaNonScelta = () => {
    return (
      <Card body outline color="success" className="mx-2 my-2">
        <Card.Body>
          <Card.Title>Per selezionare il Prodotto</Card.Title>
          <Card.Text>
            devi prima selezionare la Categoria
          </Card.Text>
        </Card.Body>
      </Card>   
    );
  };


  return ReactDom.createPortal(
    <div >
      <div
        className={props.isOpenTabAppuntamento ? "modal fade-n show" : "modal fade-out"}
        style={
          props.isOpenTabAppuntamento
            ? { ...styles, opacity: modalOpacity, display: "block" }
            : { ...styles, opacity: modalOpacity, display: "none" }
        }
        id="exampleModal"
        tabIndex="-1"
      >

        <Modal.Dialog size="lg"
              style={
                {  }
              } 
        
        >

            <Modal.Header closeButton onClick={closeModalAppuntamento}
              style={
                { backgroundColor: '#CCE5FF', height:60, align: "center" }
              } 
            
            >
        {/* 
              { backgroundColor: '#CCE5FF', height:60, border: "1px solid rgb(51 81 255)", align: "center" }

              <Typography variant="h4" sx={{ color: 'rgb(51 81 255)', fontSize: 16, my:-3, mt:0, mb: 0 }}>
                  Appuntamento&nbsp; del giorno &nbsp;<h5>{props.giorno}</h5>&nbsp; ore &nbsp;<h5>{props.ora}</h5> 
              </Typography>

              <h3>Appuntamento</h3>&nbsp; del giorno &nbsp;<h4>{props.giorno}</h4>&nbsp; ore &nbsp;<h4>{props.ora}</h4> 
        */}

            <h3>Appuntamento</h3>&nbsp;&nbsp; {props.giorno} {props.ora} riga={props.riga} col={props.colonna} cat={props.categoria} pro={props.prodotto} sit={props.sito} col={props.colore} ris={props.titoloRisorsa} dur={props.durata} own={props.owner} agenda={props.giornoAgenda?.resource0001appointments[0].resourceTitle}

            </Modal.Header>

            <Modal.Body>

                <Tabs id="uncontrolled-tab-example"                
                  className="mb-3"
                  defaultActiveKey={1}
                  activeKey={activeTab}        
                  onSelect={(key) => setActiveTab(key)}         
                >
                      
                    <Tab eventKey={1} title="Appuntamento" activeKey={2}>
        {/* 
                        <Form >
        */}        
                        <Form noValidate validated={validated} onSubmit={handleSubmit}>

                            <Box
                              component="form"
                              sx={{ '& > :not(style)': { m: 1, width: '25ch' } }}
                              noValidate
                              autoComplete="off"
                            >

                              <TextField id="outlined-basic" label="Cliente" variant="outlined" defaultValue={props.cliente} 
                                onChange={(event) => {
                                  setClienteDigitato(event.target.value);
                                }}
                              />

          {/* 
                              <TextField id="outlined-basic" label="Cliente" variant="outlined" defaultValue={clienteDigitato} 


                              <TextField id="outlined-basic" label="Ora" variant="outlined" defaultValue={props.ora} 
                                onChange={(event) => {
                                  setOraScelta(event.target.value);
                                }}                           
                              />

                              <LocalizationProvider dateAdapter={AdapterDayjs} locale={itIT} adapterLocale="it">
                                <DemoContainer components={['TimePicker']}>
                                  <TimePicker 
                                  label="Ora" 
                                  defaultValue={dayjs(props.giorno+" "+props.ora)}
                                  onChange={(event) => {
                                    setOraScelta(event.target.value);
                                  }}
                                  />
                                </DemoContainer>
                              </LocalizationProvider>
          */}

                              <TextField id="outlined-basic" label="Risorsa" variant="outlined" defaultValue={props.titoloRisorsa} 
                                slotProps={{
                                  input: {
                                    readOnly: true,
                                  },
                                }}
                              />

                              <FormControl fullWidth >
                                <InputLabel id="sel-sito">Sito</InputLabel>
                                <Select
                                  labelId="sel-sito"
                                  id="id-sel-sito"
                                  value={sitoScelto}
                                  label="Sito"
                                  onChange={handleChangeSito}      
                                  defaultValue={props.sito}
                                >
                                  {sites.map(site => {
                                            return (
                                              <MenuItem key={site.name} value={site.name}>
                                                {site.name}
                                              </MenuItem>
                                            )
                                  })}
                                </Select>
                              </FormControl>
                            </Box>

                            <Box
                              component="form"
                              sx={{ '& > :not(style)': { m: 1, width: '25ch' } }}
                              noValidate
                              autoComplete="off"
                            >
 
                              <FormControl fullWidth>
                                <InputLabel id="sel-categoria">Categoria</InputLabel>
                                <Select
                                  labelId="sel-categoria"
                                  id="id-sel-categoria"
                                  value={categoriaScelta}
                                  label="Categoria"
                                  onChange={handleChangeCategoria}
                                >
                                  {categories.map(category => {
                                            return (
                                              <MenuItem key={category.name} value={category.name}>
                                                {category.name}
                                              </MenuItem>
                                            )
                                  })}
                                </Select>
                              </FormControl>

                              <FormControl fullWidth >
                                <InputLabel id="sel-servizio">Servizio</InputLabel>
                                <Select
                                  labelId="sel-servizio"
                                  id="id-sel-servizio"
                                  value={prodottoScelto}
                                  label="Servizio"
                                  onChange={handleChangeServizio}      
                                >
                                  {products.map(product => {
                                            return (
                                              <MenuItem key={product.name} value={product.name}>
                                                {product.name}
                                              </MenuItem>
                                            )
                                  })}
                                </Select>
                              </FormControl>


        {/* 
        */}
                              <FormControl fullWidth >
                                <InputLabel id="sel-durata">Durata</InputLabel>
                                <Select
                                  labelId="sel-durata"
                                  id="id-sel-durata"
                                  value={durataScelta}
                                  label="durata"
                                  onChange={handleChangeDurata}      
                                >
                                  {durationsMins.map(duration => {
                                            return (
                                              <MenuItem key={duration.message} value={duration.valueMins}>
                                                {duration.message}
                                              </MenuItem>
                                            )
                                  })}
                                </Select>
                              </FormControl>

                            </Box>

                        </Form>
        
                    </Tab>

                    <Tab eventKey={2} title="Categoria" activeKey={activeTab} >

                        <Row xs={3} md={3} className="g mt">
                            {categories.map((category, idx) => (
                            <CardCategorySelection key={idx} category={category} idx={idx} />
                            ))}
                        </Row>

                    </Tab>

                    <Tab eventKey={3} title="Servizio">

                      {isCategoriaScelta && (

                      products.map((product) => (
                          <CardProductSelection
                            key={product._id}
                            images={product.images}
                            name={product.name}
                            description={product.description}
                            price={product.price}
                            rating={product.rating}
                            reviewsNumber={product.reviewsNumber}
                            productId={product._id}
                          />
                        )))

                      }

                      {!isCategoriaScelta && (

                          <ViewTabServizioCategoriaNonScelta></ViewTabServizioCategoriaNonScelta>

                      )}                   

                    </Tab>
    
                </Tabs>

            </Modal.Body>

            <Modal.Footer>

              <button
                type="button"
                className="btn btn-warning"
                onClick={closeModalAppuntamento}
              >
                Chiudi
              </button>

        {/* 
                  onClick={popupConfermaCancellazione()}
        */}

              <button
                  type="button"
                  className="btn btn-danger"
                  onClick={(e) => {popupConfermaCancellazione(e, props.giornoAgenda); }}
                  >
                  Cancella
              </button>

              <button
                type="button"
                className="btn btn-primary"
                onClick={(e) => {prenotazione(e, props.giornoAgenda); }}
              >
                Prenotazione
              </button>

            </Modal.Footer>

        </Modal.Dialog>

      </div>
      <div className={`background`} />
    </div>,
    modalAppuntamento
  );
};

export default ModalAppuntamento;
