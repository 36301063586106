import { Row, Col, Table, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import AdminLinksComponent from "../../../components/admin/AdminLinksComponent";

import { useState, useEffect } from "react";

import { logout } from "../../../redux/actions/userActions";
import { useDispatch } from "react-redux";

import * as React from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridActionsCellItem,
} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { Redirect, Link } from 'react-router-dom';


const AdminCustomersPageComponent = ({ fetchCustomers, deleteCustomer }) => {

  const [customers, setCustomers] = useState([]);
  const [customerDeleted, setCustomerDeleted] = useState(false); 
  const dispatch = useDispatch();

  const [finalClickInfo, setFinalClickInfo] = useState(null);

  const handleOnCellClick = (params) => {
    setFinalClickInfo(params);
    console.log('onclick params=',params)
    console.log('onclick params riga =',params.id)
    console.log('onclick params titoloColonna =',params.field)
  };
  
  const deleteHandler = async (customerId) => {
    if (window.confirm("Sei sicuro?")) {
        const data = await deleteCustomer(customerId)
        if (data.message === "customer removed") {
            setCustomerDeleted(!customerDeleted);
        }
        //navigate("/admin/customers");
        window.location.assign('/admin/customers')
    }
  };

  const paginationModel = { page: 0, pageSize: 5 };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton
          slotProps={{ tooltip: { title: 'visualizza colonne' } }}
        />
        <GridToolbarFilterButton
          slotProps={{ tooltip: { title: 'Imposta filtri' } }}
        />
        <GridToolbarDensitySelector
          slotProps={{ tooltip: { title: 'Cambia densità' } }}
        />
        <Box sx={{ flexGrow: 1 }} />
        <GridToolbarExport
          slotProps={{
            tooltip: { title: 'Esporta dati' },
            button: { variant: 'outlined' },
          }}
        />
      </GridToolbarContainer>
    );
  }

  const columns = [
    { field: 'title', headerName: 'Titolo', width: 150, headerAlign: 'center'},
    { field: 'name', headerName: 'Nome', width: 150, headerAlign: 'center' },
    { field: 'surname', headerName: 'Cognome', width: 150, headerAlign: 'center'},
    { field: 'mobile', headerName: 'Mobile', width: 120, headerAlign: 'center' },
    { field: 'email', headerName: 'Email', width: 250, headerAlign: 'center' },
    {
      field: "actions",
      type: "actions",
      headerName: 'Azioni',
      hide: true,
      width: 100,
      getActions: (params: any) => [
        <GridActionsCellItem 
          icon={<EditIcon />} 
          label="Edit" 
          component={Link}
          to={`/admin/edit-customer/${params.id}`}
        />,
        <GridActionsCellItem 
          icon={<DeleteIcon />} 
          label="Delete" 
          onClick={() => {
            deleteHandler(params.id)
          }}
        />
      ]
    },
    { field: 'telephone', headerName: 'Telefono', width: 120, headerAlign: 'center' },
    { field: 'via', headerName: 'Via', width: 150, headerAlign: 'center' },
    { field: 'cap', headerName: 'Cap', width: 80, headerAlign: 'center' },
    { field: 'citta', headerName: 'Città', width: 130, headerAlign: 'center' },
    { field: 'provincia', headerName: 'Prov', width: 50, headerAlign: 'center' }, 
  ];

  useEffect(() => {
    const abctrl = new AbortController();
    fetchCustomers(abctrl)
      .then((res) => setCustomers(res))
      .catch((er) =>
      dispatch(logout())
      );
    return () => abctrl.abort();
  }, [customerDeleted]);

  return (
    <Row className="m-5">
      <Col md={2}>
        <AdminLinksComponent />
      </Col>
      <Col md={10}>
        <h1>
          Clienti {" "} 
          <LinkContainer to="/admin/create-new-customer">
            <Button variant="primary" size="lg">
              Nuovo Cliente
            </Button>
          </LinkContainer>
        </h1>

        <Paper sx={{ height: 430, width: '100%' }}>
          <DataGrid
            getRowId={(row) => row._id}
            rows={customers}
            columns={columns}
            initialState={{ pagination: { paginationModel } }}
            pageSizeOptions={[5, 10, 15, 20]}
            slots={{
              toolbar: CustomToolbar,
            }} 
            slotProps={{
              pagination: {
                labelRowsPerPage: "righe:",               
              },
            }}
            onCellClick={handleOnCellClick}
            sx={{
                  '& .MuiDataGrid-columnHeader': 
                  {
                    backgroundColor: "cyan",
                    color: "black",
                    border: 2,
                    boxShadow: 1,
                    fontWeight: 700,                           },
                  '& .MuiDataGrid-cell': 
                  {
                    backgroundColor: "lightcyan",
                    color: "gray",
                    border: 1,
                    boxShadow: 1,
                    fontWeight: 300,
                  },
                  '& .MuiDataGrid-cell:hover':
                  {
                    backgroundColor: "#b0c0e5",
                    color: 'primary.main',              
                  },     
                  '&.Mui-selected':                          
                  {
                    color: 'primary.main',              
                  }     

            }} />
        </Paper>

  {/* 
    
    <Table striped bordered hover responsive>
        <thead>
            <tr>
              <th>#</th>
              <th>Titolo</th>
              <th>Nome</th>
              <th>Cognome</th>
              <th>Modifica/Cancella</th>
            </tr>
          </thead>
          <tbody>
            {customers.map((customer, idx) => (
              <tr key={idx}>
                <td>{idx + 1}</td>
                <td>{customer.title}</td>
                <td>{customer.name}</td>
                <td>{customer.surname}</td>
                <td>
                  <LinkContainer to={`/admin/edit-customer/${customer._id}`}>
                    <Button className="btn-sm">
                      <i className="bi bi-pencil-square"></i>
                    </Button>
                  </LinkContainer>
                  {" / "}
                  <Button
                    variant="danger"
                    className="btn-sm"
                    onClick={() => deleteHandler(customer._id)}
                  >
                    <i className="bi bi-x-circle"></i>
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
  */}


        </Col>
    </Row>
  );
};

export default AdminCustomersPageComponent;
