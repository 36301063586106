import AdminSitesPageComponent from "./components/AdminSitesPageComponent";
import axios from "axios";

const fetchSites = async (abctrl) => {
    const {data} = await axios.get("/api/sites", {
        signal: abctrl.signal,
    });
    return data
}

const deleteSite = async (userId) => {
    const { data } = await axios.delete(`/api/sites/${userId}`);
    return data
}

const AdminSitesPage = () => {
  return <AdminSitesPageComponent fetchSites={fetchSites} deleteSite={deleteSite} />;
};

export default AdminSitesPage;