const appointmentsInit =
[
    {
      "hour": "0700",
      "appointment": {
        "category": "",
        "color": "",
        "customer": "",
        "durationMins": 0,
        "product": "",
        "resourceTitle": "",
        "site": "",
        "owner": "0700"
      }
    },
    {
      "hour": "0715",
      "appointment": {
        "category": "",
        "color": "",
        "customer": "",
        "durationMins": 0,
        "product": "",
        "resourceTitle": "",
        "site": "",
        "owner": "0715"
      }
    },
    {
      "hour": "0730",
      "appointment": {
        "category": "",
        "color": "",
        "customer": "",
        "durationMins": 0,
        "product": "",
        "resourceTitle": "",
        "site": "",
        "owner": "0730"
      }
    },
    {
      "hour": "0745",
      "appointment": {
        "category": "",
        "color": "",
        "customer": "",
        "durationMins": 0,
        "product": "",
        "resourceTitle": "",
        "site": "",
        "owner": "0745"
      }
    },
    {
      "hour": "0800",
      "appointment": {
        "category": "",
        "color": "",
        "customer": "",
        "durationMins": 0,
        "product": "",
        "resourceTitle": "",
        "site": "",
        "owner": "0800"
      }
    },
    {
      "hour": "0815",
      "appointment": {
        "category": "",
        "color": "",
        "customer": "",
        "durationMins": 0,
        "product": "",
        "resourceTitle": "",
        "site": "",
        "owner": "0815"
      }
    },
    {
      "hour": "0830",
      "appointment": {
        "category": "",
        "color": "",
        "customer": "",
        "durationMins": 0,
        "product": "",
        "resourceTitle": "",
        "site": "",
        "owner": "0830"
      }
    },
    {
      "hour": "0845",
      "appointment": {
        "category": "",
        "color": "",
        "customer": "",
        "durationMins": 0,
        "product": "",
        "resourceTitle": "",
        "site": "",
        "owner": "0845"
      }
    },
    {
      "hour": "0900",
      "appointment": {
        "category": "",
        "color": "",
        "customer": "",
        "durationMins": 0,
        "product": "",
        "resourceTitle": "",
        "site": "",
        "owner": "0900"
      }
    },
    {
      "hour": "0915",
      "appointment": {
        "category": "",
        "color": "",
        "customer": "",
        "durationMins": 0,
        "product": "",
        "resourceTitle": "",
        "site": "",
        "owner": "0915"
      }
    },
    {
      "hour": "0930",
      "appointment": {
        "category": "",
        "color": "",
        "customer": "",
        "durationMins": 0,
        "product": "",
        "resourceTitle": "",
        "site": "",
        "owner": "0930"
      }
    },
    {
      "hour": "0945",
      "appointment": {
        "category": "",
        "color": "",
        "customer": "",
        "durationMins": 0,
        "product": "",
        "resourceTitle": "",
        "site": "",
        "owner": "0945"
      }
    },
    {
      "hour": "1000",
      "appointment": {
        "category": "",
        "color": "",
        "customer": "",
        "durationMins": 0,
        "product": "",
        "resourceTitle": "",
        "site": "",
        "owner": "1000"
      }
    },
    {
      "hour": "1015",
      "appointment": {
        "category": "",
        "color": "",
        "customer": "",
        "durationMins": 0,
        "product": "",
        "resourceTitle": "",
        "site": "",
        "owner": "1015"
      }
    },
    {
      "hour": "1030",
      "appointment": {
        "category": "",
        "color": "",
        "customer": "",
        "durationMins": 0,
        "product": "",
        "resourceTitle": "",
        "site": "",
        "owner": "1030"
      }
    },
    {
      "hour": "1045",
      "appointment": {
        "category": "",
        "color": "",
        "customer": "",
        "durationMins": 0,
        "product": "",
        "resourceTitle": "",
        "site": "",
        "owner": "1045"
      }
    },
    {
      "hour": "1100",
      "appointment": {
        "category": "",
        "color": "",
        "customer": "",
        "durationMins": 0,
        "product": "",
        "resourceTitle": "",
        "site": "",
        "owner": "1100"
      }
    },
    {
      "hour": "1115",
      "appointment": {
        "category": "",
        "color": "",
        "customer": "",
        "durationMins": 0,
        "product": "",
        "resourceTitle": "",
        "site": "",
        "owner": "1115"
      }
    },
    {
      "hour": "1130",
      "appointment": {
        "category": "",
        "color": "",
        "customer": "",
        "durationMins": 0,
        "product": "",
        "resourceTitle": "",
        "site": "",
        "owner": "1130"
      }
    },
    {
      "hour": "1145",
      "appointment": {
        "category": "",
        "color": "",
        "customer": "",
        "durationMins": 0,
        "product": "",
        "resourceTitle": "",
        "site": "",
        "owner": "1145"
      }
    },
    {
      "hour": "1200",
      "appointment": {
        "category": "",
        "color": "",
        "customer": "",
        "durationMins": 0,
        "product": "",
        "resourceTitle": "",
        "site": "",
        "owner": "1200"
      }
    },
    {
      "hour": "1215",
      "appointment": {
        "category": "",
        "color": "",
        "customer": "",
        "durationMins": 0,
        "product": "",
        "resourceTitle": "",
        "site": "",
        "owner": "1215"
      }
    },
    {
      "hour": "1230",
      "appointment": {
        "category": "",
        "color": "",
        "customer": "",
        "durationMins": 0,
        "product": "",
        "resourceTitle": "",
        "site": "",
        "owner": "1230"
      }
    },
    {
      "hour": "1245",
      "appointment": {
        "category": "",
        "color": "",
        "customer": "",
        "durationMins": 0,
        "product": "",
        "resourceTitle": "",
        "site": "",
        "owner": "1245"
      }
    },
    {
      "hour": "1300",
      "appointment": {
        "category": "",
        "color": "",
        "customer": "",
        "durationMins": 0,
        "product": "",
        "resourceTitle": "",
        "site": "",
        "owner": "1300"
      }
    },
    {
      "hour": "1315",
      "appointment": {
        "category": "",
        "color": "",
        "customer": "",
        "durationMins": 0,
        "product": "",
        "resourceTitle": "",
        "site": "",
        "owner": "1315"
      }
    },
    {
      "hour": "1330",
      "appointment": {
        "category": "",
        "color": "",
        "customer": "",
        "durationMins": 0,
        "product": "",
        "resourceTitle": "",
        "site": "",
        "owner": "1330"
      }
    },
    {
      "hour": "1345",
      "appointment": {
        "category": "",
        "color": "",
        "customer": "",
        "durationMins": 0,
        "product": "",
        "resourceTitle": "",
        "site": "",
        "owner": "1345"
      }
    },
    {
      "hour": "1400",
      "appointment": {
        "category": "",
        "color": "",
        "customer": "",
        "durationMins": 0,
        "product": "",
        "resourceTitle": "",
        "site": "",
        "owner": "1400"
      }
    },
    {
      "hour": "1415",
      "appointment": {
        "category": "",
        "color": "",
        "customer": "",
        "durationMins": 0,
        "product": "",
        "resourceTitle": "",
        "site": "",
        "owner": "1415"
      }
    },
    {
      "hour": "1430",
      "appointment": {
        "category": "",
        "color": "",
        "customer": "",
        "durationMins": 0,
        "product": "",
        "resourceTitle": "",
        "site": "",
        "owner": "1430"
      }
    },
    {
      "hour": "1445",
      "appointment": {
        "category": "",
        "color": "",
        "customer": "",
        "durationMins": 0,
        "product": "",
        "resourceTitle": "",
        "site": "",
        "owner": "1445"
      }
    },
    {
      "hour": "1500",
      "appointment": {
        "category": "",
        "color": "",
        "customer": "",
        "durationMins": 0,
        "product": "",
        "resourceTitle": "",
        "site": "",
        "owner": "1500"
      }
    },
    {
      "hour": "1515",
      "appointment": {
        "category": "",
        "color": "",
        "customer": "",
        "durationMins": 0,
        "product": "",
        "resourceTitle": "",
        "site": "",
        "owner": "1515"
      }
    },
    {
      "hour": "1530",
      "appointment": {
        "category": "",
        "color": "",
        "customer": "",
        "durationMins": 0,
        "product": "",
        "resourceTitle": "",
        "site": "",
        "owner": "1530"
      }
    },
    {
      "hour": "1545",
      "appointment": {
        "category": "",
        "color": "",
        "customer": "",
        "durationMins": 0,
        "product": "",
        "resourceTitle": "",
        "site": "",
        "owner": "1545"
      }
    },
    {
      "hour": "1600",
      "appointment": {
        "category": "",
        "color": "",
        "customer": "",
        "durationMins": 0,
        "product": "",
        "resourceTitle": "",
        "site": "",
        "owner": "1600"
      }
    },
    {
      "hour": "1615",
      "appointment": {
        "category": "",
        "color": "",
        "customer": "",
        "durationMins": 0,
        "product": "",
        "resourceTitle": "",
        "site": "",
        "owner": "1615"
      }
    },
    {
      "hour": "1630",
      "appointment": {
        "category": "",
        "color": "",
        "customer": "",
        "durationMins": 0,
        "product": "",
        "resourceTitle": "",
        "site": "",
        "owner": "1630"
      }
    },
    {
      "hour": "1645",
      "appointment": {
        "category": "",
        "color": "",
        "customer": "",
        "durationMins": 0,
        "product": "",
        "resourceTitle": "",
        "site": "",
        "owner": "1645"
      }
    },
    {
      "hour": "1700",
      "appointment": {
        "category": "",
        "color": "",
        "customer": "",
        "durationMins": 0,
        "product": "",
        "resourceTitle": "",
        "site": "",
        "owner": "1700"
      }
    },
    {
      "hour": "1715",
      "appointment": {
        "category": "",
        "color": "",
        "customer": "",
        "durationMins": 0,
        "product": "",
        "resourceTitle": "",
        "site": "",
        "owner": "1715"
      }
    },
    {
      "hour": "1730",
      "appointment": {
        "category": "",
        "color": "",
        "customer": "",
        "durationMins": 0,
        "product": "",
        "resourceTitle": "",
        "site": "",
        "owner": "1730"
      }
    },
    {
      "hour": "1745",
      "appointment": {
        "category": "",
        "color": "",
        "customer": "",
        "durationMins": 0,
        "product": "",
        "resourceTitle": "",
        "site": "",
        "owner": "1745"
      }
    },
    {
      "hour": "1800",
      "appointment": {
        "category": "",
        "color": "",
        "customer": "",
        "durationMins": 0,
        "product": "",
        "resourceTitle": "",
        "site": "",
        "owner": "1800"
      }
    },
    {
      "hour": "1815",
      "appointment": {
        "category": "",
        "color": "",
        "customer": "",
        "durationMins": 0,
        "product": "",
        "resourceTitle": "",
        "site": "",
        "owner": "1815"
      }
    },
    {
      "hour": "1830",
      "appointment": {
        "category": "",
        "color": "",
        "customer": "",
        "durationMins": 0,
        "product": "",
        "resourceTitle": "",
        "site": "",
        "owner": "1830"
      }
    },
    {
      "hour": "1845",
      "appointment": {
        "category": "",
        "color": "",
        "customer": "",
        "durationMins": 0,
        "product": "",
        "resourceTitle": "",
        "site": "",
        "owner": "1845"
      }
    },
    {
      "hour": "1900",
      "appointment": {
        "category": "",
        "color": "",
        "customer": "",
        "durationMins": 0,
        "product": "",
        "resourceTitle": "",
        "site": "",
        "owner": "1900"
      }
    },
    {
      "hour": "1915",
      "appointment": {
        "category": "",
        "color": "",
        "customer": "",
        "durationMins": 0,
        "product": "",
        "resourceTitle": "",
        "site": "",
        "owner": "1915"
      }
    },
    {
      "hour": "1930",
      "appointment": {
        "category": "",
        "color": "",
        "customer": "",
        "durationMins": 0,
        "product": "",
        "resourceTitle": "",
        "site": "",
        "owner": "1930"
      }
    },
    {
      "hour": "1945",
      "appointment": {
        "category": "",
        "color": "",
        "customer": "",
        "durationMins": 0,
        "product": "",
        "resourceTitle": "",
        "site": "",
        "owner": "1945"
      }
    },
    {
      "hour": "2000",
      "appointment": {
        "category": "",
        "color": "",
        "customer": "",
        "durationMins": 0,
        "product": "",
        "resourceTitle": "",
        "site": "",
        "owner": "2000"
      }
    },
    {
      "hour": "2015",
      "appointment": {
        "category": "",
        "color": "",
        "customer": "",
        "durationMins": 0,
        "product": "",
        "resourceTitle": "",
        "site": "",
        "owner": "2015"
      }
    },
    {
      "hour": "2030",
      "appointment": {
        "category": "",
        "color": "",
        "customer": "",
        "durationMins": 0,
        "product": "",
        "resourceTitle": "",
        "site": "",
        "owner": "2030"
      }
    },
    {
      "hour": "2045",
      "appointment": {
        "category": "",
        "color": "",
        "customer": "",
        "durationMins": 0,
        "product": "",
        "resourceTitle": "",
        "site": "",
        "owner": "2045"
      }
    },
    {
      "hour": "2100",
      "appointment": {
        "category": "",
        "color": "",
        "customer": "",
        "durationMins": 0,
        "product": "",
        "resourceTitle": "",
        "site": "",
        "owner": "2100"
      }
    },
    {
      "hour": "2115",
      "appointment": {
        "category": "",
        "color": "",
        "customer": "",
        "durationMins": 0,
        "product": "",
        "resourceTitle": "",
        "site": "",
        "owner": "2115"
      }
    },
    {
      "hour": "2130",
      "appointment": {
        "category": "",
        "color": "",
        "customer": "",
        "durationMins": 0,
        "product": "",
        "resourceTitle": "",
        "site": "",
        "owner": "2130"
      }
    },
    {
      "hour": "2145",
      "appointment": {
        "category": "",
        "color": "",
        "customer": "",
        "durationMins": 0,
        "product": "",
        "resourceTitle": "",
        "site": "",
        "owner": "2145"
      }
    },
    {
      "hour": "2200",
      "appointment": {
        "category": "",
        "color": "",
        "customer": "",
        "durationMins": 0,
        "product": "",
        "resourceTitle": "",
        "site": "",
        "owner": "2200"
      }
    }
  ]

  module.exports = appointmentsInit