import {
  Navbar,
  Nav,
  Container,
  NavDropdown,
  Badge,
  Form,
  DropdownButton,
  Dropdown,
  Button,
  InputGroup,
} from "react-bootstrap";

import { LinkContainer } from "react-router-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../redux/actions/userActions.js";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { getCategories } from "../redux/actions/categoryActions.js";
import socketIOClient from "socket.io-client";
import { setChatRooms, setSocket, setMessageReceived, removeChatRoom } from "../redux/actions/chatActions.js";

const config = require("../constants/constants.js")

const HeaderComponent2styleB = () => {
  const dispatch = useDispatch();
  const { userInfo } = useSelector((state) => state.userRegisterLogin);
  const itemsCount = useSelector((state) => state.cart.itemsCount);
  const { categories } = useSelector((state) => state.getCategories);
  const { messageReceived } = useSelector((state) => state.adminChat);

  const [searchCategoryToggle, setSearchCategoryToggle] = useState("Tutte le categorie");
  const [searchQuery, setSearchQuery] = useState("");

  const navigate = useNavigate();

  const url_customer_image_tel = config.URL_IMAGES_CUSTOMER+"tel-cust"+config.CUSTOMER_CODE+".png";  // logo-cust0001.png 
  const url_customer_image_logo = config.URL_IMAGES_CUSTOMER+"logo-cust"+config.CUSTOMER_CODE+".png";  // logo-cust0001.png 

  useEffect(() => {
    dispatch(getCategories());
  }, [dispatch]);

  const submitHandler = (e) => {
     if (e.keyCode && e.keyCode !== 13) return;
     e.preventDefault();
     if (searchQuery.trim()) {
         if (searchCategoryToggle === "Tutte le categorie") {
             navigate(`/product-list/search/${searchQuery}`);
         } else {
             navigate(`/product-list/category/${searchCategoryToggle.replaceAll("/", ",")}/search/${searchQuery}`);
         }
     } else if (searchCategoryToggle !== "Tutte le categorie") {
         navigate(`/product-list/category/${searchCategoryToggle.replaceAll("/", ",")}`);
     } else {
         navigate("/product-list");
     }
  }

  useEffect(() => {
      if (userInfo.isAdmin) {
          var audio = new Audio("/audio/chat-msg.mp3");
          const socket = socketIOClient();
          socket.emit("admin connected with server", "Admin" + Math.floor(Math.random() * 1000000000000));
          socket.on("server sends message from client to admin", ({user, message}) => {
              dispatch(setSocket(socket));
        //   let chatRooms = {
        //     fddf54gfgfSocketID: [{ "client": "dsfdf" }, { "client": "dsfdf" }, { "admin": "dsfdf" }],
        //   };
            dispatch(setChatRooms(user, message));      
             dispatch(setMessageReceived(true));  
             audio.play();
          })
          socket.on("disconnected", ({reason, socketId}) => {
            //   console.log(socketId, reason)
            dispatch(removeChatRoom(socketId));
          })
          return () => socket.disconnect();
      }
  },[userInfo.isAdmin])

         {/* 
              PER ESSERE RESPONSIVE ANCHE NELLE 2 RIGHE DI MENU 
              devo gestire 
                height: "40px",
                height: "auto",
        */}

  return (
    <Navbar collapseOnSelect expand="lg" bg="light" data-bs-theme="light">
      <Container 
        style={{
          height: "auto",
        }}
      >
        
        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
       
        <Navbar.Collapse id="responsive-navbar-nav">

          <Navbar expand="sm" >
          
            <Nav>

              <LinkContainer to="/home-menu">
                <Nav.Link className="mx-2">HOME</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/progetti">
                <Nav.Link className="mx-2">APPUNTAMENTI</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/">
                <Nav.Link className="mx-2">SERVIZI</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/chi-siamo">
                <Nav.Link className="mx-2">CHI SIAMO</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/staff">
                <Nav.Link className="mx-2">STAFF</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/contatti">
                <Nav.Link className="mx-2">CONTATTI</Nav.Link>
              </LinkContainer>

          {/* 
              <LinkContainer to="/competenza">
                <Nav.Link className="mx-2">AREE COMPETENZA</Nav.Link>
              </LinkContainer>
              <LinkContainer to="/progetti">
                <Nav.Link className="mx-2">PROGETTI</Nav.Link>
              </LinkContainer>
              <NavDropdown title="CONVEGNI EVENTI" id="collasible-nav-dropdown">
                <LinkContainer to="/convegni-eventi">
                  <NavDropdown.Item> Eventi importanti </NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/convegni-argomenti">
                  <NavDropdown.Item> Argomenti </NavDropdown.Item>
                </LinkContainer>
                <LinkContainer to="/convegni-date">
                  <NavDropdown.Item> Prossime date </NavDropdown.Item>
                </LinkContainer>
              </NavDropdown>

              <LinkContainer to="/news">
                <Nav.Link className="mx-2">NEWS</Nav.Link>
              </LinkContainer>
          */}
              
            </Nav>
          </Navbar> 

        </Navbar.Collapse>

      </Container>
      
    </Navbar>

);
};

export default HeaderComponent2styleB;
