import {
  Row,
  Col,
  Container,
  Form,
  Button,
  CloseButton,
  Table,
  Alert,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { changeCategory, setValuesForAttrFromDbSelectForm, setAttributesTableWrapper } from "./utils/utils";
import InputGroup from 'react-bootstrap/InputGroup';

const CreateCategoryPageComponent = ({
  createCategoryApiRequest,
  uploadCategoryImagesApiRequest,
  uploadImagesCloudinaryApiRequest,
  reduxDispatch,
  newCategory,
  deleteCategory,
  saveAttributeToCatDoc
}) => {
  const [validated, setValidated] = useState(false);
  const [attributesTable, setAttributesTable] = useState([]);
  const [attributesFromDb, setAttributesFromDb] = useState([]);
  const [images, setImages] = useState(false);
  const [isCreating, setIsCreating] = useState("");
  const [createProductResponseState, setCreateCategoryResponseState] = useState({
    message: "",
    error: "",
  });
  const [categoryChoosen, setCategoryChoosen] = useState("Choose category");

  const [newAttrKey, setNewAttrKey] = useState(false);
  const [newAttrValue, setNewAttrValue] = useState(false);

  const attrVal = useRef(null);
  const attrKey = useRef(null);
  const createNewAttrKey = useRef(null);
  const createNewAttrVal = useRef(null);

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget.elements;
    const formInputs = {
      name: form.name.value,
      description: form.description.value,
      color: form.color.value,
    };
//    if (event.currentTarget.checkValidity() === true) {
      
      if (images.length > 1) {
        setIsCreating("to many files");
        return;
      }

      createCategoryApiRequest(formInputs)
        .then((data) => {
          if (images) {
            if (process.env.NODE_ENV !== "production") {
              uploadCategoryImagesApiRequest(images, data.categoryId)
                .then((res) => {})
                .catch((er) =>
                  setIsCreating(
                    er.response.data.message
                      ? er.response.data.message
                      : er.response.data
                  )
                );
            } else {
              uploadImagesCloudinaryApiRequest(images, data.categoryId);
            }
          }
          if (data.message === "category created") navigate("/admin/categories");
        })
        .catch((er) => {
          setCreateCategoryResponseState({
            error: er.response.data.message
              ? er.response.data.message
              : er.response.data,
          });
        });
  //  }

    setValidated(true);

    window.location.assign('/admin/categories')
  };

  const uploadHandler = (images) => {
    setImages(images);
  };

  const newCategoryHandler = (e) => {
    if (e.keyCode && e.keyCode === 13 && e.target.value) {
      reduxDispatch(newCategory(e.target.value));
      setTimeout(() => {
        let element = document.getElementById("cats");
        setCategoryChoosen(e.target.value);
        element.value = e.target.value;
        e.target.value = "";
      }, 200);
    }
  };

  const deleteCategoryHandler = () => {
    let element = document.getElementById("cats");
    reduxDispatch(deleteCategory(element.value));
    setCategoryChoosen("Choose category");
  };

  const attributeValueSelected = (e) => {
      if (e.target.value !== "Choose attribute value") {
          setAttributesTableWrapper(attrKey.current.value, e.target.value, setAttributesTable);
      }
  }

  const deleteAttribute = (key) => {
      setAttributesTable((table) => table.filter((item) => item.key !== key));
  }

  const newAttrKeyHandler = (e) => {
      e.preventDefault();
      setNewAttrKey(e.target.value);
      addNewAttributeManually(e);
  }

  const newAttrValueHandler = (e) => {
      e.preventDefault();
      setNewAttrValue(e.target.value);
      addNewAttributeManually(e);

  }

  const addNewAttributeManually = (e) => {
      if (e.keyCode && e.keyCode === 13) {
           if (newAttrKey && newAttrValue) {
              reduxDispatch(saveAttributeToCatDoc(newAttrKey, newAttrValue, categoryChoosen));
               setAttributesTableWrapper(newAttrKey, newAttrValue, setAttributesTable);
               e.target.value = "";
               createNewAttrKey.current.value = "";
               createNewAttrVal.current.value = "";
               setNewAttrKey(false);
               setNewAttrValue(false);
           }

      }
  }

  const checkKeyDown = (e) => {
      if (e.code === "Enter") e.preventDefault();
  }

  return (
    <Container>
      <Row className="justify-content-md-center mt-5">
        <Col md={1}>
          <Link to="/admin/categories" className="btn btn-info my-3">
            Indietro
          </Link>
        </Col>
        <Col md={6}>
          <h1>Crea nuova categoria</h1>

          <Form noValidate validated={validated} onSubmit={handleSubmit} onKeyDown={(e) => checkKeyDown(e)} >

            <InputGroup className="mb-3">
              <InputGroup.Text id="inputGroup-sizing-default"
                style={{
                  width: "100px",
                 }}>
                Nome
              </InputGroup.Text>
              <Form.Control name="name" required type="text" />
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroup.Text id="inputGroup-sizing-default"
                style={{
                  width: "100px",
                 }}>
                Descrizione
              </InputGroup.Text>
              <Form.Control name="description" required type="text" />
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroup.Text id="inputGroup-sizing-default"
                style={{
                  width: "100px",
                 }}>
                Colore
              </InputGroup.Text>
              <Form.Control name="color" required type="color" />
            </InputGroup>

        {/* 
            <Form.Group className="mb-3" controlId="formBasicName">
              <Form.Label>Nome</Form.Label>
              <Form.Control name="name" required type="text" />
            </Form.Group>

            <Form.Group
              className="mb-3"
              controlId="exampleForm.ControlTextarea1"
            >
              <Form.Label>Descrizione</Form.Label>
              <Form.Control
                name="description"
                required
                as="textarea"
                rows={3}
              />
            </Form.Group>           
        */}

            <Form.Group controlId="formFileMultiple" className="mb-3 mt-3">
              <Form.Label>Immagine della categoria (formato .png / .jpg)</Form.Label>
              <Form.Control
                required
                type="file"
                multiple
                onChange={(e) => uploadHandler(e.target.files)}
              />
              {isCreating}
            </Form.Group>

            <Button variant="primary" type="submit">
              Crea
            </Button>
            {createProductResponseState.error ?? ""}
          </Form>

        </Col>

      </Row>

      <Row className="mt-5 justify-content-md-center">
        <Col md={6}>
        </Col>
      </Row>

    </Container>
  );
};

export default CreateCategoryPageComponent;
