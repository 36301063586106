const resourcesInit =
[
  {
    "column": 0,
    "resourceTitle": "Giorgia",
    "appointmentsProperty": "resource0001appointments"
  },
  {
    "column": 1,
    "resourceTitle": "Vanessa",
    "appointmentsProperty": "resource0002appointments"
  },
  {
    "column": 2,
    "resourceTitle": "Guilia",
    "appointmentsProperty": "resource0003appointments"
  },
  {
    "column": 3,
    "resourceTitle": "Luisa",
    "appointmentsProperty": "resource0004appointments"
  },
  {
    "column": 4,
    "resourceTitle": "Antonella",
    "appointmentsProperty": "resource0005appointments"
  },
  {
    "column": 5,
    "resourceTitle": "Michela",
    "appointmentsProperty": "resource0006appointments"
  }
] 

module.exports = resourcesInit