import CreateCustomerPageComponent from "./components/CreateCustomerPageComponent";
import axios from "axios";
import { useSelector } from "react-redux";
import { newCustomer, deleteCustomer, saveAttributeToCatDoc } from "../../redux/actions/customerActions";
import { useDispatch } from "react-redux";

const createCustomerApiRequest = async (formInputs) => {
  const { data } = await axios.post(`/api/customers`, { ...formInputs });
  return data;
};

const AdminCreateCustomerPage = () => {
  const { customers } = useSelector((state) => state.getCategories);
  const dispatch = useDispatch();

  return (
    <CreateCustomerPageComponent
      createCustomerApiRequest={createCustomerApiRequest}
      customers={customers}
      reduxDispatch={dispatch}
      newCustomer={newCustomer}
      deleteCustomer={deleteCustomer}
      saveAttributeToCatDoc={saveAttributeToCatDoc}
    />
  );
};

export default AdminCreateCustomerPage;
