import EditCustomerPageComponent from "./components/EditCustomerPageComponent";
import axios from "axios";

const fetchCustomer = async (customerId) => {
    const { data } = await axios.get(`/api/customers/${customerId}`);
    return data;
}

const updateCustomerApiRequest = async (customerId, title, name, surname, telephone, mobile, email, via, cap, citta, provincia, piva, codicefiscale, image) => {
    const { data } = await axios.put(`/api/customers/${customerId}`, { title, name, surname, telephone, mobile, email, via, cap, citta, provincia, piva, codicefiscale, image});
    return data;
}

const AdminEditCustomerPage = () => {
  
  return <EditCustomerPageComponent updateCustomerApiRequest={updateCustomerApiRequest} fetchCustomer={fetchCustomer} />;
};


export default AdminEditCustomerPage;
