import CreateUserPageComponent from "./components/CreateUserPageComponent";
import axios from "axios";
import { useSelector } from "react-redux";
import { newUser, deleteUser, saveAttributeToCatDoc } from "../../redux/actions/userActions";
import { useDispatch } from "react-redux";

const registerUserApiRequest = async (name, lastName, email, password, isAdmin) => {
  const { data } = await axios.post("/api/users/register", {
    name,
    lastName,
    email,
    password,
    isAdmin
  });
  sessionStorage.setItem("userInfo", JSON.stringify(data.userCreated));
  if (data.success === "User created") window.location.href = "/user";
  return data;
};

const createUserApiRequest = async (formInputs) => {
  const { data } = await axios.post(`/api/users`, { ...formInputs });
  return data;
};

const AdminCreateUserPage = () => {
  const { users } = useSelector((state) => state.getCategories);
  const dispatch = useDispatch();

  return (
    <CreateUserPageComponent
      createUserApiRequest={createUserApiRequest}
      registerUserApiRequest={registerUserApiRequest}
      users={users}
      reduxDispatch={dispatch}
      newUser={newUser}
      deleteUser={deleteUser}
      saveAttributeToCatDoc={saveAttributeToCatDoc}
    />
  );
};

export default AdminCreateUserPage;
