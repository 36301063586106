import {
  Row,
  Col,
  Container,
  Form,
  Button,
  CloseButton,
  Table,
  Alert,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { changeSite, setValuesForAttrFromDbSelectForm, setAttributesTableWrapper } from "./utils/utils";
import InputGroup from 'react-bootstrap/InputGroup';

const CreateSitePageComponent = ({
  createSiteApiRequest,
  reduxDispatch,
  newSite,
  deleteSite,
  saveAttributeToCatDoc
}) => {
  const [validated, setValidated] = useState(false);
  const [attributesTable, setAttributesTable] = useState([]);
  const [attributesFromDb, setAttributesFromDb] = useState([]);
  const [images, setImages] = useState(false);
  const [isCreating, setIsCreating] = useState("");
  const [createProductResponseState, setCreateSiteResponseState] = useState({
    message: "",
    error: "",
  });
  const [siteChoosen, setSiteChoosen] = useState("Choose site");

  const [newAttrKey, setNewAttrKey] = useState(false);
  const [newAttrValue, setNewAttrValue] = useState(false);

  const attrVal = useRef(null);
  const attrKey = useRef(null);
  const createNewAttrKey = useRef(null);
  const createNewAttrVal = useRef(null);

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget.elements;
    const formInputs = {
      name: form.name.value,
      description: form.description.value,
      code: form.code.value,
      address: form.address.value,
      image: form.image.value,
    };

    createSiteApiRequest(formInputs);

    setValidated(true);

    window.location.assign('/admin/sites')
  };

  const uploadHandler = (images) => {
    setImages(images);
  };

  const newSiteHandler = (e) => {
    if (e.keyCode && e.keyCode === 13 && e.target.value) {
      reduxDispatch(newSite(e.target.value));
      setTimeout(() => {
        let element = document.getElementById("cats");
        setSiteChoosen(e.target.value);
        element.value = e.target.value;
        e.target.value = "";
      }, 200);
    }
  };

  const deleteSiteHandler = () => {
    let element = document.getElementById("cats");
    reduxDispatch(deleteSite(element.value));
    setSiteChoosen("Choose site");
  };

  const attributeValueSelected = (e) => {
      if (e.target.value !== "Choose attribute value") {
          setAttributesTableWrapper(attrKey.current.value, e.target.value, setAttributesTable);
      }
  }

  const deleteAttribute = (key) => {
      setAttributesTable((table) => table.filter((item) => item.key !== key));
  }

  const newAttrKeyHandler = (e) => {
      e.preventDefault();
      setNewAttrKey(e.target.value);
      addNewAttributeManually(e);
  }

  const newAttrValueHandler = (e) => {
      e.preventDefault();
      setNewAttrValue(e.target.value);
      addNewAttributeManually(e);

  }

  const addNewAttributeManually = (e) => {
      if (e.keyCode && e.keyCode === 13) {
           if (newAttrKey && newAttrValue) {
              reduxDispatch(saveAttributeToCatDoc(newAttrKey, newAttrValue, siteChoosen));
               setAttributesTableWrapper(newAttrKey, newAttrValue, setAttributesTable);
               e.target.value = "";
               createNewAttrKey.current.value = "";
               createNewAttrVal.current.value = "";
               setNewAttrKey(false);
               setNewAttrValue(false);
           }

      }
  }

  const checkKeyDown = (e) => {
      if (e.code === "Enter") e.preventDefault();
  }

  return (
    <Container>
      <Row className="justify-content-md-center mt-5">
        <Col md={1}>
          <Link to="/admin/sites" className="btn btn-info my-3">
            Indietro
          </Link>
        </Col>
        <Col md={6}>
          <h1>Crea nuovo sito</h1>
          <Form noValidate validated={validated} onSubmit={handleSubmit} onKeyDown={(e) => checkKeyDown(e)} >

            <Row className="mt-5 justify-content-md-center"></Row>

            <InputGroup className="mb-3">
              <InputGroup.Text id="inputGroup-sizing-default"
                style={{
                  width: "100px",
                 }}>
                Nome
              </InputGroup.Text>
              <Form.Control name="name" required type="text" />
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroup.Text id="inputGroup-sizing-default"
                style={{
                  width: "100px",
                 }}>
                Descrizione
              </InputGroup.Text>
              <Form.Control name="description" required type="text" />
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroup.Text id="inputGroup-sizing-default"
                style={{
                  width: "100px",
                 }}>
                Codice
              </InputGroup.Text>
              <Form.Control name="code" required type="text" />
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroup.Text id="inputGroup-sizing-default"
                style={{
                  width: "100px",
                 }}>
                Indirizzo
              </InputGroup.Text>
              <Form.Control name="address" required type="text" />
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroup.Text id="inputGroup-sizing-default"
                style={{
                  width: "100px",
                 }}>
                Immagine
              </InputGroup.Text>
              <Form.Control name="image" required type="text" />
            </InputGroup>

            <Button variant="primary" type="submit">
              Crea
            </Button>
            {createProductResponseState.error ?? ""}
          </Form>

        </Col>

      </Row>

      <Row className="mt-5 justify-content-md-center"></Row>

    </Container>
  );
};

export default CreateSitePageComponent;
