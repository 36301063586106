import EditSitePageComponent from "./components/EditSitePageComponent";
import axios from "axios";

const fetchSite = async (siteId) => {
    const { data } = await axios.get(`/api/sites/${siteId}`);
    return data;
}

const updateSiteApiRequest = async (siteId, name, description, code , address, image) => {
    const { data } = await axios.put(`/api/sites/${siteId}`, { name, description, code , address, image });
    return data;
}

const AdminEditSitePage = () => {
  
  return <EditSitePageComponent updateSiteApiRequest={updateSiteApiRequest} fetchSite={fetchSite} />;
};


export default AdminEditSitePage;
