import {
  Row,
  Col,
  Container,
  Form,
  Button,
  CloseButton,
  Table,
  Alert,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import React, { useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { changeResource, setValuesForAttrFromDbSelectForm, setAttributesTableWrapper } from "./utils/utils";
import InputGroup from 'react-bootstrap/InputGroup';

const CreateResourcePageComponent = ({
  createResourceApiRequest,
  reduxDispatch,
  newResource,
  deleteResource,
  saveAttributeToCatDoc
}) => {
  const [validated, setValidated] = useState(false);
  const [attributesTable, setAttributesTable] = useState([]);
  const [attributesFromDb, setAttributesFromDb] = useState([]);
  const [images, setImages] = useState(false);
  const [isCreating, setIsCreating] = useState("");
  const [createProductResponseState, setCreateResourceResponseState] = useState({
    message: "",
    error: "",
  });
  const [resourceChoosen, setResourceChoosen] = useState("Choose resource");

  const [newAttrKey, setNewAttrKey] = useState(false);
  const [newAttrValue, setNewAttrValue] = useState(false);

  const attrVal = useRef(null);
  const attrKey = useRef(null);
  const createNewAttrKey = useRef(null);
  const createNewAttrVal = useRef(null);

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget.elements;
    const formInputs = {
      title: form.title.value,
      name: form.name.value,
      surname: form.surname.value,
      telephone: form.telephone.value,
      email: form.email.value,
      address: form.address.value,
      image: form.image.value,
    };

    createResourceApiRequest(formInputs);

    setValidated(true);

    window.location.assign('/admin/resources')
  };

  const uploadHandler = (images) => {
    setImages(images);
  };

  const newResourceHandler = (e) => {
    if (e.keyCode && e.keyCode === 13 && e.target.value) {
      reduxDispatch(newResource(e.target.value));
      setTimeout(() => {
        let element = document.getElementById("cats");
        setResourceChoosen(e.target.value);
        element.value = e.target.value;
        e.target.value = "";
      }, 200);
    }
  };

  const deleteResourceHandler = () => {
    let element = document.getElementById("cats");
    reduxDispatch(deleteResource(element.value));
    setResourceChoosen("Choose resource");
  };

  const attributeValueSelected = (e) => {
      if (e.target.value !== "Choose attribute value") {
          setAttributesTableWrapper(attrKey.current.value, e.target.value, setAttributesTable);
      }
  }

  const deleteAttribute = (key) => {
      setAttributesTable((table) => table.filter((item) => item.key !== key));
  }

  const newAttrKeyHandler = (e) => {
      e.preventDefault();
      setNewAttrKey(e.target.value);
      addNewAttributeManually(e);
  }

  const newAttrValueHandler = (e) => {
      e.preventDefault();
      setNewAttrValue(e.target.value);
      addNewAttributeManually(e);

  }

  const addNewAttributeManually = (e) => {
      if (e.keyCode && e.keyCode === 13) {
           if (newAttrKey && newAttrValue) {
              reduxDispatch(saveAttributeToCatDoc(newAttrKey, newAttrValue, resourceChoosen));
               setAttributesTableWrapper(newAttrKey, newAttrValue, setAttributesTable);
               e.target.value = "";
               createNewAttrKey.current.value = "";
               createNewAttrVal.current.value = "";
               setNewAttrKey(false);
               setNewAttrValue(false);
           }

      }
  }

  const checkKeyDown = (e) => {
      if (e.code === "Enter") e.preventDefault();
  }

  return (
    <Container>
      <Row className="justify-content-md-center mt-5">
        <Col md={1}>
          <Link to="/admin/resources" className="btn btn-info my-3">
            Indietro
          </Link>
        </Col>
        <Col md={6}>
          <h1>Crea nuova risorsa</h1>
          <Form noValidate validated={validated} onSubmit={handleSubmit} onKeyDown={(e) => checkKeyDown(e)} >

            <Row className="mt-5 justify-content-md-center"></Row>

            <InputGroup className="mb-3">
              <InputGroup.Text id="inputGroup-sizing-default"
                style={{
                  width: "100px",
                 }}>
                Titolo
              </InputGroup.Text>
              <Form.Control name="title" required type="text" />
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroup.Text id="inputGroup-sizing-default"
                style={{
                  width: "100px",
                 }}>
                Nome
              </InputGroup.Text>
              <Form.Control name="name" required type="text" />
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroup.Text id="inputGroup-sizing-default"
                style={{
                  width: "100px",
                 }}>
                Cognome
              </InputGroup.Text>
              <Form.Control name="surname" required type="text" />
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroup.Text id="inputGroup-sizing-default"
                style={{
                  width: "100px",
                 }}>
                Telefono
              </InputGroup.Text>
              <Form.Control name="telephone" required type="text" />
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroup.Text id="inputGroup-sizing-default"
                style={{
                  width: "100px",
                 }}>
                Email
              </InputGroup.Text>
              <Form.Control name="email" required type="text" />
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroup.Text id="inputGroup-sizing-default" 
                style={{
                  width: "100px",
                 }}>
                Indirizzo
              </InputGroup.Text>
              <Form.Control name="address" required type="text" />
            </InputGroup>


            <InputGroup className="mb-3">
              <InputGroup.Text id="inputGroup-sizing-default"
                style={{
                  width: "100px",
                 }}>
                Immagine
              </InputGroup.Text>
              <Form.Control name="image" required type="text" />
            </InputGroup>

            <Button variant="primary" type="submit">
              Crea
            </Button>
            {createProductResponseState.error ?? ""}
          </Form>

        </Col>

      </Row>

      <Row className="mt-5 justify-content-md-center"></Row>

    </Container>
  );
};

export default CreateResourcePageComponent;
