import * as actionTypes from "../constants/customerConstants";

import axios from "axios";

export const getCustomers = () => async (dispatch) => {
    const { data } = await axios.get("/api/customers");
    dispatch({
        type: actionTypes.GET_CUSTOMERS_REQUEST,
        payload: data,
    })
}

export const saveAttributeToCatDoc = (key, val, customerChoosen) => async (dispatch, getState) => {
   const { data } = await axios.post("/api/customers/attr", { key, val, customerChoosen }); 
   if (data.customerUpdated) {
       dispatch({
           type: actionTypes.SAVE_ATTR,
           payload: [...data.customerUpdated],
       })
   }
}

export const newCustomer = (customer) => async (dispatch, getState) => {
    const res = getState().getCustomers.customers;
    const { data } = await axios.post("/api/customers", { customer });
    if (data.customerCreated) {
        dispatch({
            type: actionTypes.INSERT_CUSTOMER,
            payload: [...res, data.customerCreated],
        })
    }
}

export const deleteCustomer = (customer) => async (dispatch, getState) => {
    const res = getState().getCustomers.customers;
    const customers = res.filter((item) => item.name !== customer);
    const { data } = await axios.delete("/api/customers/" + encodeURIComponent(customer));
    if (data.customerDeleted) {
        dispatch({
           type: actionTypes.DELETE_CUSTOMER, 
           payload: [...customers],
        })
    }
}