import { Row, Col, Container, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { InputGroup } from "react-bootstrap";

const EditCategoryPageComponent = ({ updateCategoryApiRequest, fetchCategory }) => {
  const [validated, setValidated] = useState(false);
  const [category, setCategory] = useState([]);
  const [updateCategoryResponseState, setUpdateCategoryResponseState] = useState({ message: "", error: "" });

  const { id } = useParams();
  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    
    const form = event.currentTarget.elements;
    const name = form.name.value;
    const description = form.description.value;
    const color = form.color.value;
    const image = form.image.value;

    if (event.currentTarget.checkValidity() === true) {
        updateCategoryApiRequest(id, name, description, color, image)
        .then(data => {
            if (data.success === "category updated") {
                navigate("/admin/categories");
            }
          })
        .catch(er => {
            setUpdateCategoryResponseState({ error: er.response.data.message ? er.response.data.message : er.response.data });
        })
    }

    setValidated(true);
  };

  const [coloreScelto, setColoreScelto] = useState('#ffffff');
  const coloreScelto1 = '#536ef3';

  useEffect(() => {
      fetchCategory(id)
      .then(data => {
          setCategory(data);
      })
      .catch((er) => console.log(er.response.data.message ? er.response.data.message : er.response.data));
      console.log('category = ',category)
  }, [id])

  useEffect(() => {
    setColoreScelto(category.color);
    //console.log('color = ',category)
    console.log('coloreScelto = ',coloreScelto)
  }, [])

  return (
    <Container>

      <Row className="justify-content-md-center mt-5">
        <Col md={1}>
          <Link to="/admin/categories" className="btn btn-info my-3">
            Indietro
          </Link>
        </Col>
        <Col md={6}>
          
          <h1>Modifica categoria</h1>

          <Form noValidate validated={validated} onSubmit={handleSubmit}>

            <InputGroup className="mb-3">
                <InputGroup.Text id="inputGroup-sizing-default"
                  style={{
                    width: "100px",
                  }}>
                  Nome
                </InputGroup.Text>
                <Form.Control name="name" defaultValue={category.name} required type="text" />
            </InputGroup>

            <InputGroup className="mb-3">
                <InputGroup.Text id="inputGroup-sizing-default"
                  style={{
                    width: "100px",
                  }}>
                  Descrizione
                </InputGroup.Text>
                <Form.Control name="description" defaultValue={category.description} required type="text" />
            </InputGroup>

            <InputGroup className="mb-3">
                <InputGroup.Text id="inputGroup-sizing-default"
                  style={{
                    width: "100px",
                  }}>
                  Colore
                </InputGroup.Text>
                <Form.Control name="color" defaultValue={coloreScelto} required type="color" />
            </InputGroup>

            <Form.Group className="mb-3" controlId="formBasicLastName">
              <Form.Label>Immagine</Form.Label>
              <Form.Control
                name="image"
                required
                type="text"
                defaultValue={category.image} 
              />
            </Form.Group>

            <Button variant="primary" type="submit">
              MODIFICA
            </Button>
            {updateCategoryResponseState.error}

          </Form>
        </Col>
      </Row>

      <Row className="mt-5 justify-content-md-center">
        <Col md={6}>
        </Col>
      </Row>

    </Container>
  );
};

export default EditCategoryPageComponent;
