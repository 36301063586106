import { Row, Col, Table, Button } from "react-bootstrap";
import { LinkContainer } from "react-router-bootstrap";
import AdminLinksComponent from "../../../components/admin/AdminLinksComponent";
import { useState, useEffect } from "react";
import { logout } from "../../../redux/actions/userActions";
import { useDispatch } from "react-redux";

import * as React from 'react';
import Paper from '@mui/material/Paper';
import Box from '@mui/material/Box';
import {
  DataGrid,
  GridToolbarContainer,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarExport,
  GridToolbarDensitySelector,
  GridActionsCellItem,
} from '@mui/x-data-grid';
import EditIcon from '@mui/icons-material/Edit'
import DeleteIcon from '@mui/icons-material/Delete'
import { Redirect, Link } from 'react-router-dom';

const ProductsPageComponent = ({ fetchProducts, deleteProduct }) => {
 
  const [products, setProducts] = useState([]);
  const [productDeleted, setProductDeleted] = useState(false); 
  const dispatch = useDispatch();

  const [finalClickInfo, setFinalClickInfo] = useState(null);

  const handleOnCellClick = (params) => {
    setFinalClickInfo(params);
    console.log('onclick params=',params)
    console.log('onclick params riga =',params.id)
    console.log('onclick params titoloColonna =',params.field)
  };
  
  const deleteHandler = async (productId) => {
    if (window.confirm("Sei sicuro?")) {
        const data = await deleteProduct(productId)
        if (data.message === "product removed") {
            setProductDeleted(!productDeleted);
        }
        //navigate("/admin/products");
        window.location.assign('/admin/products')
    }
  };

  const paginationModel = { page: 0, pageSize: 5 };

  function CustomToolbar() {
    return (
      <GridToolbarContainer>
        <GridToolbarColumnsButton
          slotProps={{ tooltip: { title: 'visualizza colonne' } }}
        />
        <GridToolbarFilterButton
          slotProps={{ tooltip: { title: 'Imposta filtri' } }}
        />
        <GridToolbarDensitySelector
          slotProps={{ tooltip: { title: 'Cambia densità' } }}
        />
        <Box sx={{ flexGrow: 1 }} />
        <GridToolbarExport
          slotProps={{
            tooltip: { title: 'Esporta dati' },
            button: { variant: 'outlined' },
          }}
        />
      </GridToolbarContainer>
    );
  }

  const columns = [
    { field: 'name', headerName: 'Nome', width: 250, headerAlign: 'center' },
    { field: 'description', headerName: 'Descrizione', width: 250, headerAlign: 'center'},
    { field: 'category', headerName: 'Categoria', width: 250, headerAlign: 'center' },
    { field: 'color', headerName: 'Colore', width: 100, headerAlign: 'center', aligh:"center"},
    { field: 'price', headerName: 'Prezzo', width: 100, headerAlign: 'center'},
    {
      field: "actions",
      type: "actions",
      headerName: 'Azioni',
      hide: true,
      width: 100,
      getActions: (params: any) => [
        <GridActionsCellItem 
          icon={<EditIcon />} 
          label="Edit" 
          component={Link}
          to={`/admin/edit-product/${params.id}`}
        />,
        <GridActionsCellItem 
          icon={<DeleteIcon />} 
          label="Delete" 
          onClick={() => {
            deleteHandler(params.id)
          }}
        />
      ]
    },
    { field: 'images[0]', headerName: 'Immagine 1', width: 200, headerAlign: 'center' },
    { field: 'images[1]', headerName: 'Immagine 2', width: 200, headerAlign: 'center' },
    { field: 'images[2]', headerName: 'Immagine 3', width: 200, headerAlign: 'center' },

  ];

//  { field: 'images', headerName: 'Immagine 1', width: 200, headerAlign: 'center' },    render=>[object Object],[object Ob


  useEffect(() => {
    const abctrl = new AbortController();
    fetchProducts(abctrl)
      .then((res) => setProducts(res))
      .catch((er) =>
      dispatch(logout())
        // setProducts([
        //   {name: er.response.data.message ? er.response.data.message : er.response.data}
        // ])
      );
    return () => abctrl.abort();
  }, [productDeleted]);

  return (

    <Row className="m-5">
    
      <Col md={2}>
        <AdminLinksComponent />
      </Col>
    
      <Col md={10}>
        <h1>
          Servizi{" "}
          <LinkContainer to="/admin/create-new-product">
            <Button variant="primary" size="lg">
              Nuovo Servizio
            </Button>
          </LinkContainer>
        </h1>

        <Paper sx={{ height: 430, width: '100%' }}>
          <DataGrid
            getRowId={(row) => row._id}
            rows={products}
            columns={columns}
            initialState={{ pagination: { paginationModel } }}
            pageSizeOptions={[5, 10, 15, 20]}
            slots={{
              toolbar: CustomToolbar,
            }} 
            slotProps={{
              pagination: {
                labelRowsPerPage: "righe:",               
              },
            }}
            onCellClick={handleOnCellClick}
            sx={{
                  '& .MuiDataGrid-columnHeader': 
                  {
                    backgroundColor: "cyan",
                    color: "black",
                    border: 2,
                    boxShadow: 1,
                    fontWeight: 700,                           },
                  '& .MuiDataGrid-cell': 
                  {
                    backgroundColor: "lightcyan",
                    color: "gray",
                    border: 1,
                    boxShadow: 1,
                    fontWeight: 300,
                  },
                  '& .MuiDataGrid-cell:hover':
                  {
                    backgroundColor: "#b0c0e5",
                    color: 'primary.main',              
                  },     
                  '&.Mui-selected':                          
                  {
                    color: 'primary.main',              
                  }     
            
            }} />
        </Paper>

        {/* 
        <Table striped bordered hover responsive>
          <thead>
            <tr>
              <th>#</th>
              <th>Nome</th>
              <th>Categoria</th>
              <th>Prezzo</th>
              <th>Modifica/Cancella</th>
            </tr>
          </thead>
          <tbody>
            {products.map((item, idx) => (
              <tr key={idx}>
                <td>{idx + 1}</td>
                <td>{item.name}</td>
                <td>{item.category}</td>
                <td>{item.price}</td>
                <td>
                  <LinkContainer to={`/admin/edit-product/${item._id}`}>
                    <Button className="btn-sm">
                      <i className="bi bi-pencil-square"></i>
                    </Button>
                  </LinkContainer>
                  {" / "}
                  <Button
                    variant="danger"
                    className="btn-sm"
                    onClick={() => deleteHandler(item._id)}
                  >
                    <i className="bi bi-x-circle"></i>
                  </Button>
                </td>
              </tr>
            ))}
          </tbody>
        </Table>
        */}


        </Col>
    </Row>
  );
};

export default ProductsPageComponent;
