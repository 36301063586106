import { Row, Col, Container, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { InputGroup } from "react-bootstrap";
const EditResourcePageComponent = ({ updateResourceApiRequest, fetchResource }) => {
  const [validated, setValidated] = useState(false);
    const [resource, setResource] = useState([]);
    const [updateResourceResponseState, setUpdateResourceResponseState] = useState({ message: "", error: "" });

    const { id } = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        fetchResource(id)
        .then(data => {
            setResource(data);
        })
        .catch((er) => console.log(er.response.data.message ? er.response.data.message : er.response.data));
    }, [id])

  return (
    <Container>

      <Row className="justify-content-md-center mt-5">
        <Col md={1}>
          <Link to="/admin/resources" className="btn btn-info my-3">
            Indietro
          </Link>
        </Col>
        <Col md={6}>
          <h1>Modifica risorsa</h1>

          <Row className="mt-5 justify-content-md-center"></Row>

          <Form noValidate validated={validated} >

            <InputGroup className="mb-3">
                <InputGroup.Text id="inputGroup-sizing-default"
                  style={{
                    width: "100px",
                  }}>
                  Titolo
                </InputGroup.Text>
                <Form.Control name="title" defaultValue={resource.title} required type="text" />
            </InputGroup>

            <InputGroup className="mb-3">
                <InputGroup.Text id="inputGroup-sizing-default"
                  style={{
                    width: "100px",
                  }}>
                  Nome
                </InputGroup.Text>
                <Form.Control name="name" defaultValue={resource.name} required type="text" />
            </InputGroup>

            <InputGroup className="mb-3">
                <InputGroup.Text id="inputGroup-sizing-default"
                  style={{
                    width: "100px",
                  }}>
                  Cognome
                </InputGroup.Text>
                <Form.Control name="surname" defaultValue={resource.surname} required type="text" />
            </InputGroup>

            <InputGroup className="mb-3">
                <InputGroup.Text id="inputGroup-sizing-default"
                  style={{
                    width: "100px",
                  }}>
                  Telefono
                </InputGroup.Text>
                <Form.Control name="telephone" defaultValue={resource.telephone} required type="text" />
            </InputGroup>

            <InputGroup className="mb-3">
                <InputGroup.Text id="inputGroup-sizing-default"
                  style={{
                    width: "100px",
                  }}>
                  Email
                </InputGroup.Text>
                <Form.Control name="email" defaultValue={resource.email} required type="text" />
            </InputGroup>

            <InputGroup className="mb-3">
                <InputGroup.Text id="inputGroup-sizing-default"
                  style={{
                    width: "100px",
                  }}>
                  Indirizzo
                </InputGroup.Text>
                <Form.Control name="address" defaultValue={resource.address} required type="text" />
            </InputGroup>

            <InputGroup className="mb-3">
              <InputGroup.Text id="inputGroup-sizing-default"
                    style={{
                      width: "100px",
                    }}>
                    Immagine
                </InputGroup.Text>
                <Form.Control name="image" defaultValue={resource.image} required type="text" />
            </InputGroup>

            <Button variant="primary" type="submit">
              MODIFICA
            </Button>
            {updateResourceResponseState.error}

          </Form>
        </Col>
      </Row>

      <Row className="mt-5 justify-content-md-center"></Row>

    </Container>
  );
};

export default EditResourcePageComponent;
