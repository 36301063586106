//import { LOGIN_USER, LOGOUT_USER } from '../constants/userConstants'
import * as actionTypes from "../constants/userConstants";

export const userRegisterLoginReducer = (state = {}, action) => {
    switch (action.type) {
        case actionTypes.LOGIN_USER:
           return {
               ...state,
               userInfo: action.payload
           } 
        case actionTypes.LOGOUT_USER:
            return {};

        case actionTypes.GET_USERS_REQUEST:
            return {
                ...state,
                customers: action.payload,
            }
            case actionTypes.SAVE_ATTR:
                return {
                    ...state,
                    customers: action.payload,
                }
            case actionTypes.INSERT_USER:
                return {
                    ...state,
                    customers: action.payload,
                }
            case actionTypes.DELETE_USER:
                return {
                ...state,
                customers: action.payload,
                }

        default:
            return state
    }
}