import {
  Row,
  Col,
  Container,
  Form,
  Button,
  CloseButton,
  Table,
  Alert,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { changeCustomer, setValuesForAttrFromDbSelectForm, setAttributesTableWrapper } from "./utils/utils";
import InputGroup from 'react-bootstrap/InputGroup';

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { red } from "@mui/material/colors";

const CreateCustomerPageComponent = ({
  createCustomerApiRequest,
  reduxDispatch,
  newCustomer,
  deleteCustomer,
  saveAttributeToCatDoc
}) => {
  const [validated, setValidated] = useState(false);
  const [attributesTable, setAttributesTable] = useState([]);
  const [attributesFromDb, setAttributesFromDb] = useState([]);
  const [images, setImages] = useState(false);
  const [isCreating, setIsCreating] = useState("");
  const [createProductResponseState, setCreateCustomerResponseState] = useState({
    message: "",
    error: "",
  });
  const [customerChoosen, setCustomerChoosen] = useState("Choose customer");

  const [newAttrKey, setNewAttrKey] = useState(false);
  const [newAttrValue, setNewAttrValue] = useState(false);

  const attrVal = useRef(null);
  const attrKey = useRef(null);
  const createNewAttrKey = useRef(null);
  const createNewAttrVal = useRef(null);

  const navigate = useNavigate();

  const handleSubmit = (event) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget.elements;
    const formInputs = {
      title: form.title.value,
      name: form.name.value,
      surname: form.surname.value,
      telephone: form.telephone.value,
      mobile: form.mobile.value,
      email: form.email.value,
      via: form.via.value,
      cap: form.cap.value,
      citta: form.citta.value,
      provincia: form.provincia.value,
      piva: form.piva.value,
      codicefiscale: form.codicefiscale.value,
      image: form.image.value,
    };

    createCustomerApiRequest(formInputs);

    setValidated(true);

    window.location.assign('/admin/customers')
  };

  const uploadHandler = (images) => {
    setImages(images);
  };

  const newCustomerHandler = (e) => {
    if (e.keyCode && e.keyCode === 13 && e.target.value) {
      reduxDispatch(newCustomer(e.target.value));
      setTimeout(() => {
        let element = document.getElementById("cats");
        setCustomerChoosen(e.target.value);
        element.value = e.target.value;
        e.target.value = "";
      }, 200);
    }
  };

  const deleteCustomerHandler = () => {
    let element = document.getElementById("cats");
    reduxDispatch(deleteCustomer(element.value));
    setCustomerChoosen("Choose customer");
  };

  const attributeValueSelected = (e) => {
      if (e.target.value !== "Choose attribute value") {
          setAttributesTableWrapper(attrKey.current.value, e.target.value, setAttributesTable);
      }
  }

  const deleteAttribute = (key) => {
      setAttributesTable((table) => table.filter((item) => item.key !== key));
  }

  const newAttrKeyHandler = (e) => {
      e.preventDefault();
      setNewAttrKey(e.target.value);
      addNewAttributeManually(e);
  }

  const newAttrValueHandler = (e) => {
      e.preventDefault();
      setNewAttrValue(e.target.value);
      addNewAttributeManually(e);

  }

  const addNewAttributeManually = (e) => {
      if (e.keyCode && e.keyCode === 13) {
           if (newAttrKey && newAttrValue) {
              reduxDispatch(saveAttributeToCatDoc(newAttrKey, newAttrValue, customerChoosen));
               setAttributesTableWrapper(newAttrKey, newAttrValue, setAttributesTable);
               e.target.value = "";
               createNewAttrKey.current.value = "";
               createNewAttrVal.current.value = "";
               setNewAttrKey(false);
               setNewAttrValue(false);
           }

      }
  }

  const checkKeyDown = (e) => {
      if (e.code === "Enter") e.preventDefault();
  }

  const [clienteDigitato1, setClienteDigitato1] = useState("");   
  const [title, setTitle] = useState("");                      
  const [name, setName] = useState("");                      
  const [surname, setSurname] = useState("");                      
  const [telephone, setTelephone] = useState("");                      
  const [mobile, setMobile] = useState("");                      
  const [email, setEmail] = useState("");                      
  const [via, setVia] = useState("");                      
  const [cap, setCap] = useState("");                      
  const [citta, setCitta] = useState("");                      
  const [provincia, setProvincia] = useState("");                      
  const [piva, setPiva] = useState("");                      
  const [codicefiscale, setCodicefiscale] = useState("");                      

  useEffect(() => {
    //setClienteDigitato1("uno");
    //setClienteDigitato(props.cliente);
  }, []);

  function createNewCustomer (eventKey) {
    eventKey.preventDefault();
    eventKey.stopPropagation();

    console.log(`title dig =`, clienteDigitato1);  
    console.log(`name dig =`, name);  
    console.log(`surname dig =`, surname);  
    console.log(`telephone dig =`, telephone);  
    console.log(`mobile dig =`, mobile);  
    console.log(`email dig =`, email);  
    console.log(`via dig =`, via);  
    console.log(`cap dig =`, cap);  
    console.log(`citta dig =`, citta);  
    console.log(`provincia dig =`, provincia);  
    console.log(`piva dig =`, piva);  
    console.log(`codicefiscale dig =`, codicefiscale);  

    const formInputs = {
      title: clienteDigitato1,
      name: name,
      surname: surname,
      telephone: telephone,
      mobile: mobile,
      email: email,
      via: via,
      cap: cap,
      citta: citta,
      provincia: provincia,
      piva: piva,
      codicefiscale: codicefiscale,
      image: "",
    };

    createCustomerApiRequest(formInputs);
    window.location.assign('/admin/customers')
  }

  function gotoIndietro (eventKey) {
    eventKey.preventDefault();
    eventKey.stopPropagation();

    window.location.assign('/admin/customers')
  }


  return (

    <Container fullWidth>
      
      <Row className="justify-content-center mt-5">

        <Col md={11}>
          <h1>&nbsp;Crea nuovo cliente</h1>

          <Form noValidate validated={validated} onSubmit={handleSubmit} onKeyDown={(e) => checkKeyDown(e)} >

            <Box
              component="form"
              sx={{ '& > :not(style)': { m: 1, width: '25ch' } }}
              noValidate
              autoComplete="off"
            >

              <TextField id="id-cliente" label="Cliente" variant="outlined" defaultValue={clienteDigitato1}
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setClienteDigitato1(event.target.value);
                }}
              />

              <TextField id="id-name" label="Nome" variant="outlined" defaultValue={name}
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setName(event.target.value);
                }}
              />

              <TextField id="id-surname" label="Cognome" variant="outlined" defaultValue={surname} 
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setSurname(event.target.value);
                }}
              />

              <TextField id="id-telephone" label="Telefono" variant="outlined" defaultValue={telephone} 
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setTelephone(event.target.value);
                }}
              />

              <TextField id="id-mobile" label="Mobile" variant="outlined" defaultValue={mobile} 
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setMobile(event.target.value);
                }}
              />

              <TextField id="id-email" label="Email" variant="outlined" defaultValue={email} 
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
              />

              <TextField id="id-via" label="Via" variant="outlined" defaultValue={via} 
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setVia(event.target.value);
                }}
              />

              <TextField id="id-cap" label="CAP" variant="outlined" defaultValue={cap} 
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setCap(event.target.value);
                }}
              />

              <TextField id="id-citta" label="Città" variant="outlined" defaultValue={citta} 
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setCitta(event.target.value);
                }}
              />

              <TextField id="id-provincia" label="Provincia" variant="outlined" defaultValue={provincia} 
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setProvincia(event.target.value);
                }}
              />

              <TextField id="id-piva" label="Partita iva" variant="outlined" defaultValue={piva} 
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setPiva(event.target.value);
                }}
              />

              <TextField id="id-codicefiscale" label="Codice fiscale" variant="outlined" defaultValue={codicefiscale} 
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setCodicefiscale(event.target.value);
                }}
              />
           
          </Box>

          </Form>

        </Col>

      </Row>

      <Row className="justify-content-center mt-5">
        <Col md={1}>
  
        <Button 
              type="button"
              className="btn btn-warning"
              onClick={(e) => {gotoIndietro(e); }}
              style={{
                width: "80px",
              }}
            >
              Indietro
            </Button>             

         </Col>

          <Col md={1}>
            <Button 
              type="button"
              className="btn btn-primary"
              onClick={(e) => {createNewCustomer(e); }}
              style={{
                width: "80px",
              }}
            >
              Crea
            </Button>             

            {createProductResponseState.error ?? ""}
          </Col>
      </Row>

      <Row className="mt-5 justify-content-md-center"></Row>

    </Container>
  );
};

export default CreateCustomerPageComponent;
