import AdminCustomersPageComponent from "./components/AdminCustomersPageComponent";
import axios from "axios";

const fetchCustomers = async (abctrl) => {
    const {data} = await axios.get("/api/customers", {
        signal: abctrl.signal,
    });
    return data
}

const deleteCustomer = async (userId) => {
    const { data } = await axios.delete(`/api/customers/${userId}`);
    return data
}

const AdminCustomersPage = () => {
  return <AdminCustomersPageComponent fetchCustomers={fetchCustomers} deleteCustomer={deleteCustomer} />;
};

export default AdminCustomersPage;