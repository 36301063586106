import { Row, Col, Container, Form, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { InputGroup } from "react-bootstrap";

import Box from '@mui/material/Box';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import { red } from "@mui/material/colors";

const EditCustomerPageComponent = ({ updateCustomerApiRequest, fetchCustomer }) => {

  const [validated, setValidated] = useState(false);
  const [customer1, setCustomer1] = useState([]);
  const [updateCustomerResponseState, setUpdateCustomerResponseState] = useState({ message: "", error: "" });

  const { id } = useParams();
  const navigate = useNavigate();

  const checkKeyDown = (e) => {
    if (e.code === "Enter") e.preventDefault();
  }

  const [title, setTitle] = useState("");                      
  const [name, setName] = useState("");                      
  const [surname, setSurname] = useState("");                      
  const [telephone, setTelephone] = useState("");                      
  const [mobile, setMobile] = useState("");                      
  const [email, setEmail] = useState("");                      
  const [via, setVia] = useState("");                      
  const [cap, setCap] = useState("");                      
  const [citta, setCitta] = useState("");                      
  const [provincia, setProvincia] = useState("");                      
  const [piva, setPiva] = useState("");                      
  const [codicefiscale, setCodicefiscale] = useState("");                      
  const [image, setImage] = useState("");                      

  useEffect(() => {
      fetchCustomer(id)
      .then(data => {
        console.log(`data =`, data);  
        setTitle(data?.title);
        setName(data?.name);
        setSurname(data?.surname);
        setTelephone(data?.telephone);
        setMobile(data?.mobile);
        setEmail(data?.email);
        setVia(data?.via);
        setCap(data?.cap);
        setCitta(data?.citta);
        setProvincia(data?.provincia);
        setPiva(data?.piva);
        setCodicefiscale(data?.codicefiscale);
        console.log(`name  =`, name);  
        console.log(`surname  =`, surname);  
        console.log(`provincia  =`, provincia);  
      })
      .catch((er) => console.log(er.response.data.message ? er.response.data.message : er.response.data));         
  },[id])

  function salvaCustomerModificato (eventKey) {
    eventKey.preventDefault();
    eventKey.stopPropagation();

    console.log(`title dig =`, title);  
    console.log(`name dig =`, name);  
    console.log(`surname dig =`, surname);  
    console.log(`telephone dig =`, telephone);  
    console.log(`mobile dig =`, mobile);  
    console.log(`email dig =`, email);  
    console.log(`via dig =`, via);  
    console.log(`cap dig =`, cap);  
    console.log(`citta dig =`, citta);  
    console.log(`provincia dig =`, provincia);  
    console.log(`piva dig =`, piva);  
    console.log(`codicefiscale dig =`, codicefiscale);  

    updateCustomerApiRequest(id, title, name, surname, telephone, mobile, email, via, cap, citta, provincia, piva, codicefiscale, image)
    .then(data => {
        if (data.success === "customer updated") {
            navigate("/admin/customers");
        }
      })
    .catch(er => {
        setUpdateCustomerResponseState({ error: er.response.data.message ? er.response.data.message : er.response.data });
    })

    window.location.assign('/admin/customers')
  }

  function gotoIndietro (eventKey) {
    eventKey.preventDefault();
    eventKey.stopPropagation();

    window.location.assign('/admin/customers')
  }

  return (
    <Container fullWidth>

      <Row className="justify-content-center mt-5">

        <Col md={11}>
          <h1>&nbsp;Modifica cliente</h1>

          <Form noValidate validated={validated} onKeyDown={(e) => checkKeyDown(e)} >

            <Box
              component="form"
              sx={{ '& > :not(style)': { m: 1, width: '25ch' } }}
              noValidate
              autoComplete="off"
            >

              <TextField id="id-cliente" label="Cliente" variant="outlined" value={title} defaultValue={title}
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setTitle(event.target.value);
                }}
              />

              <TextField id="id-name" label="Nome" variant="outlined" value={name} defaultValue={name}
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setName(event.target.value);
                }}
              />

              <TextField id="id-surname" label="Cognome" variant="outlined" value={surname} defaultValue={surname} 
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setSurname(event.target.value);
                }}
              />

              <TextField id="id-telephone" label="Telefono" variant="outlined" value={telephone} defaultValue={telephone} 
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setTelephone(event.target.value);
                }}
              />

              <TextField id="id-mobile" label="Mobile" variant="outlined" value={mobile} defaultValue={mobile} 
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setMobile(event.target.value);
                }}
              />

              <TextField id="id-email" label="Email" variant="outlined" value={email} defaultValue={email} 
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setEmail(event.target.value);
                }}
              />

              <TextField id="id-via" label="Via" variant="outlined" value={via} defaultValue={via} 
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setVia(event.target.value);
                }}
              />

              <TextField id="id-cap" label="CAP" variant="outlined" value={cap} defaultValue={cap} 
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setCap(event.target.value);
                }}
              />

              <TextField id="id-citta" label="Città" variant="outlined" value={citta} defaultValue={citta} 
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setCitta(event.target.value);
                }}
              />

              <TextField id="id-provincia" label="Provincia" variant="outlined" value={provincia} defaultValue={provincia} 
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setProvincia(event.target.value);
                }}
              />

              <TextField id="id-piva" label="Partita iva" variant="outlined" value={piva} defaultValue={piva} 
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setPiva(event.target.value);
                }}
              />

              <TextField id="id-codicefiscale" label="Codice fiscale" variant="outlined" value={codicefiscale} defaultValue={codicefiscale} 
                style={{
                  width: "200px",
                }}
                onChange={(event) => {
                  setCodicefiscale(event.target.value);
                }}
              />
           
            </Box>

          </Form>

        </Col>

      </Row>

      <Row className="justify-content-center mt-5">
        <Col md={1}>
  
        <Button 
              type="button"
              className="btn btn-warning"
              onClick={(e) => {gotoIndietro(e); }}
              style={{
                width: "80px",
              }}
            >
              Indietro
            </Button>             

         </Col>

          <Col md={1}>
            <Button 
              type="button"
              className="btn btn-primary"
              onClick={(e) => {salvaCustomerModificato(e); }}
              style={{
                width: "80px",
              }}
            >
              Salva
            </Button>             

          </Col>
      </Row>

      <Row className="mt-5 justify-content-md-center"></Row>

    </Container>
  );
};

export default EditCustomerPageComponent;
